import React from "react";
import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xs: "auto", md: "100vh" },
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      {/* Top Bar Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
          flexWrap: "wrap",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(2) }}
        >
          <Skeleton variant="text" width={200} height={50} />
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Box>

      {/* Filters Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
          flexWrap: "wrap", // allow filters to wrap
          gap: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", gap: theme.spacing(2), flexWrap: "wrap" }}>
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={150} height={40} />
          <Skeleton variant="rectangular" width={150} height={40} />
        </Box>
        <Box sx={{ display: "flex", gap: theme.spacing(2), flexWrap: "wrap" }}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: 250, sm: 300, md: 400 },
              height: 40,
            }}
          />
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Box>

      {/* Table Skeleton */}
      <Box sx={{ flexGrow: 1, overflowX: "auto", marginTop: theme.spacing(1) }}>
        <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[...Array(10)].map((_, i) => (
                  <TableCell key={i}>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(5)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {[...Array(10)].map((__, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton variant="text" width={100} height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Pagination Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: theme.spacing(2),
          flexWrap: "wrap",
          gap: theme.spacing(2),
        }}
      >
        <Skeleton variant="text" width={200} height={30} />
        <Skeleton
          variant="rectangular"
          sx={{
            width: { xs: 200, sm: 250, md: 300 },
            height: 40,
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
