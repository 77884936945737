import React from "react";
import { Box, Typography, Tooltip, SxProps, Theme } from "@mui/material";
import { parseIdentity } from "../../../utils/parseIdentity";

interface IdentityDisplayProps {
  identity: string;
  textSx?: SxProps<Theme>; // optional SX prop
}

const IdentityDisplay: React.FC<IdentityDisplayProps> = ({
  identity,
  textSx,
}) => {
  const { name, email } = parseIdentity(identity);

  return (
    <Box
      sx={{
        maxWidth: 75,
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {name ? (
        <Tooltip title={name} placement="bottom-start">
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              whiteSpace: "wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...textSx, // Merged styling here
            }}
          >
            {name}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...textSx, // Merged styling here
          }}
        >
          {name || "<none>"}
        </Typography>
      )}
    </Box>
  );
};

export default IdentityDisplay;
