import React, { useState } from "react";
import { useTheme } from "@mui/system";
import { motion, AnimatePresence } from "framer-motion";

// Import your local SVG assets
import screenshotReview from "../../../assets/screenshot-review.svg";
import openDetails from "../../../assets/open-details.svg";
import openQuestions from "../../../assets/open-questions.svg";

import "../styles/RadialMenu.css";

/**
 * Helper: create a donut “slice” path for angles [startDeg, endDeg].
 */
function createDonutSlicePath(
  cx: number,
  cy: number,
  innerR: number,
  outerR: number,
  startDeg: number,
  endDeg: number
) {
  const delta = endDeg - startDeg;
  const largeArcFlag = delta <= 180 ? 0 : 1;

  const startRad = (Math.PI / 180) * startDeg;
  const endRad = (Math.PI / 180) * endDeg;

  // Outer arc start
  const x1Outer = cx + outerR * Math.cos(startRad);
  const y1Outer = cy + outerR * Math.sin(startRad);

  // Outer arc end
  const x2Outer = cx + outerR * Math.cos(endRad);
  const y2Outer = cy + outerR * Math.sin(endRad);

  // Inner arc start (reverse)
  const x1Inner = cx + innerR * Math.cos(endRad);
  const y1Inner = cy + innerR * Math.sin(endRad);

  // Inner arc end
  const x2Inner = cx + innerR * Math.cos(startRad);
  const y2Inner = cy + innerR * Math.sin(startRad);

  return `
    M ${x1Outer},${y1Outer}
    A ${outerR},${outerR} 0 ${largeArcFlag},1 ${x2Outer},${y2Outer}
    L ${x1Inner},${y1Inner}
    A ${innerR},${innerR} 0 ${largeArcFlag},0 ${x2Inner},${y2Inner}
    Z
  `;
}

interface SliceDef {
  id: string;
  startDeg: number;
  endDeg: number;
  label?: string; // e.g. "Similar"
  icon?: string; // path to the SVG
}

interface RadialMenuProps {
  isOpen: boolean;
  onSliceClick: (sliceLabel: string) => void;
  isMalicious?: boolean;
  scaleFactor?: number; // new prop for up/down scaling the donut
}

/**
 * A donut menu with four slices, offset by 45°,
 * using Framer Motion to animate the arcs in/out.
 */
const RadialMenu: React.FC<RadialMenuProps> = ({
  isOpen,
  onSliceClick,
  isMalicious = false,
  scaleFactor = 1.0,
}) => {
  const theme = useTheme();
  const [selectedSlice, setSelectedSlice] = useState<string | null>(null);

  // Instead of fixed 60 / 100, we multiply by scaleFactor
  const baseInnerR = 60;
  const baseOuterR = 100;
  const innerRadius = baseInnerR * scaleFactor;
  const outerRadius = baseOuterR * scaleFactor;

  // Also adjust the <svg> size
  const svgSize = 200 * scaleFactor;
  const cx = 100 * scaleFactor;
  const cy = 100 * scaleFactor;

  // Slices (each 90°)
  const slices: SliceDef[] = [
    {
      id: "Empty",
      startDeg: 45,
      endDeg: 135,
    },
    {
      id: "Preview",
      startDeg: 135,
      endDeg: 225,
      label: "Preview",
      icon: screenshotReview,
    },
    {
      id: "Questions",
      startDeg: 225,
      endDeg: 315,
      label: "Questions",
      icon: openQuestions,
    },
    {
      id: "Details",
      startDeg: 315,
      endDeg: 405,
      label: "Details",
      icon: openDetails,
    },
  ];

  // Slice color logic
  const getSliceColor = (sliceId: string) => {
    if (selectedSlice === sliceId) {
      return isMalicious ? theme.palette.error.main : theme.palette.info.main;
    }
    return isMalicious ? theme.palette.error.light : theme.palette.info.light;
  };

  // Text color logic
  const getTextColor = (sliceId: string) => {
    if (selectedSlice === sliceId) {
      return theme.palette.primary.contrastText;
    }
    return theme.palette.text.primary;
  };

  // For label + icon placement
  const labelRadius = (innerRadius + outerRadius) / 2;
  function midpointAngle(startDeg: number, endDeg: number) {
    return (startDeg + endDeg) / 2;
  }

  return (
    <div className={`radial-menu ${isOpen ? "expanded" : ""}`}>
      <svg
        className="donut"
        width={svgSize}
        height={svgSize}
        viewBox={`0 0 ${svgSize} ${svgSize}`}
      >
        <AnimatePresence>
          {isOpen &&
            slices.map((slice) => {
              const pathD = createDonutSlicePath(
                cx,
                cy,
                innerRadius,
                outerRadius,
                slice.startDeg,
                slice.endDeg
              );
              const fillColor = getSliceColor(slice.id);
              const strokeColor = theme.palette.divider;

              return (
                <motion.g
                  key={slice.id}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <motion.path
                    d={pathD}
                    fill={fillColor}
                    stroke={strokeColor}
                    strokeWidth={2}
                    style={{
                      cursor: slice.id === "Empty" ? "default" : "pointer",
                    }}
                    onClick={() => {
                      if (slice.id === "Empty") return;
                      setSelectedSlice(slice.id);
                      onSliceClick(slice.id);
                    }}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    exit={{ pathLength: 0 }}
                    transition={{ duration: 0.1 }}
                  />
                  {slice.label && slice.icon && (
                    <motion.g
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.1, delay: 0.05 }}
                    >
                      {(() => {
                        const angle = midpointAngle(
                          slice.startDeg,
                          slice.endDeg
                        );
                        const midRad = (Math.PI / 180) * angle;
                        const lx =
                          cx +
                          ((innerRadius + outerRadius) / 2) * Math.cos(midRad);
                        const ly =
                          cy +
                          ((innerRadius + outerRadius) / 2) * Math.sin(midRad);

                        const textColor = getTextColor(slice.id);

                        return (
                          <>
                            <image
                              x={lx - 8 * scaleFactor} // half the icon’s scaled width
                              y={ly - 10 * scaleFactor}
                              href={slice.icon}
                              width={16 * scaleFactor}
                              height={16 * scaleFactor}
                              style={{ pointerEvents: "none" }}
                            />
                            <text
                              x={lx}
                              y={ly + 15}
                              fill={textColor}
                              fontSize={0.5 * scaleFactor + "rem"}
                              fontWeight="400"
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              pointerEvents="none"
                            >
                              {slice.label}
                            </text>
                          </>
                        );
                      })()}
                    </motion.g>
                  )}
                </motion.g>
              );
            })}
        </AnimatePresence>
      </svg>
    </div>
  );
};

export default RadialMenu;
