import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, useTheme, Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../src/app/rootReducer";
import { Case } from "../features/cases/types";
import { useAppDispatch } from "../app/hooks";
import { fetchCases } from "../../src/api/casesApi";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSkeleton from "../features/cases/components/LoadingSkeleton";
import ErrorDisplay from "../features/cases/components/ErrorDisplay";
import TopBar from "../features/cases/components/TopBar";
import FiltersSection from "../features/cases/components/FiltersSection";
import PaginationControls from "../features/cases/components/PaginationControls";
import dayjs from "dayjs";
import UploadEmailModal from "../features/cases/components/UploadEmailModal";
import { Link as RouterLink } from "react-router-dom";
import {
  UploadItem,
  useUploadManager,
} from "../features/upload/components/UploadManager";
import EnhancedCasesTable from "./EnhancedCasesTable";

interface CustomQuickFilter {
  name: string;
  filters: any;
}

function getInitialCasesListState() {
  const savedState = JSON.parse(
    localStorage.getItem("CasesListPageState") || "{}"
  );

  const areAdvancedFiltersEmpty =
    !savedState.advancedFilters ||
    Object.values(savedState.advancedFilters).every((val: any) =>
      Array.isArray(val) ? val.length === 0 : !val
    );

  return {
    activeFilter: savedState.activeFilter || "Escalated",
    searchTerm: savedState.searchTerm || "",
    advancedFilters: areAdvancedFiltersEmpty
      ? null
      : savedState.advancedFilters,
    page: savedState.page || 1,
    order: savedState.order || undefined,
    orderBy: savedState.orderBy || undefined,
  };
}

function areAdvancedFiltersEmpty(filters: any): boolean {
  if (!filters) return true;

  const {
    conclusions = [],
    statuses = [],
    severities = [],
    createdAt = [],
    alertTimestamp = [],
    identities = [],
  } = filters;

  const isEmptyArray = (arr: any[]) => !arr || arr.length === 0;
  const noDateSelected = (dates: [any, any]) =>
    !dates || (!dates[0] && !dates[1]);

  return (
    isEmptyArray(conclusions) &&
    isEmptyArray(statuses) &&
    isEmptyArray(severities) &&
    noDateSelected(createdAt) &&
    noDateSelected(alertTimestamp) &&
    isEmptyArray(identities)
  );
}

const CasesListPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // Redux store data
  const cases = useSelector((state: RootState) => state.cases.cases);
  const loading = useSelector((state: RootState) => state.cases.loading);
  const error = useSelector((state: RootState) => state.cases.error);

  // Upload manager
  const {
    uploads,
    addUploadItem,
    markUploadReady,
    markUploadError,
    pollCaseStatus,
  } = useUploadManager();

  // We'll use this to show the "check" icon for 5s whenever a new item becomes ready
  const [showCheck, setShowCheck] = useState(false);

  // We'll also show a snackbar with a link to the newly ready item
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  // Track previously-known "ready" IDs
  const previousUploadsRef = useRef<UploadItem[]>(uploads);

  useEffect(() => {
    // Compare old vs new to find any newly "ready" items
    const previousUploads = previousUploadsRef.current;
    const newlyReadyItems = uploads.filter((currentItem) => {
      if (currentItem.status !== "ready") return false;
      const oldVersion = previousUploads.find((p) => p.id === currentItem.id);
      return oldVersion && oldVersion.status !== "ready";
    });

    if (newlyReadyItems.length > 0) {
      setShowCheck(true);
      setTimeout(() => setShowCheck(false), 5000);

      const lastReady = newlyReadyItems[newlyReadyItems.length - 1];
      setSnackbarMessage(`Analysis complete for "${lastReady.fileName}" - `);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    }

    previousUploadsRef.current = uploads;
  }, [uploads]);

  // Load initial filter state
  const {
    activeFilter: initialActiveFilter,
    searchTerm: initialSearchTerm,
    advancedFilters: initialAdvancedFilters,
    page: initialPage,
    order: initialOrder,
    orderBy: initialOrderBy,
  } = getInitialCasesListState();

  // Local states
  const [activeFilter, setActiveFilter] = useState(initialActiveFilter);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [advancedFilters, setAdvancedFilters] = useState<any>(
    initialAdvancedFilters
  );
  const [page, setPage] = useState(initialPage);
  const rowsPerPage = 15;

  // Because we're now doing sorting in the EnhancedCasesTable, we might not
  // strictly need order/orderBy here, but we'll keep it for backward compatibility
  const [order, setOrder] = useState<"asc" | "desc" | undefined>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof Case | undefined>(
    initialOrderBy
  );

  const [filteredCases, setFilteredCases] = useState<Case[]>([]);

  // Modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const handleOpenUploadModal = () => setIsUploadModalOpen(true);
  const handleCloseUploadModal = () => setIsUploadModalOpen(false);

  // user custom filters
  const [customFilters, setCustomFilters] = useState<CustomQuickFilter[]>([]);

  function loadCustomFilters(): CustomQuickFilter[] {
    try {
      const stored = localStorage.getItem("CustomQuickFilters");
      return stored ? JSON.parse(stored) : [];
    } catch {
      return [];
    }
  }

  function saveCustomFilters(filtersToSave: CustomQuickFilter[]) {
    localStorage.setItem("CustomQuickFilters", JSON.stringify(filtersToSave));
  }

  useEffect(() => {
    const loaded = loadCustomFilters();
    setCustomFilters(loaded);
  }, []);

  const handleSaveCustomFilter = (filterName: string, filters: any) => {
    const finalName = filterName.substring(0, 8);
    const newFilter: CustomQuickFilter = { name: finalName, filters };
    const updated = [...customFilters];
    updated.unshift(newFilter);
    if (updated.length > 3) {
      updated.pop();
    }
    setCustomFilters(updated);
    saveCustomFilters(updated);
  };

  const applyCustomFilterByName = (filterName: string) => {
    const found = customFilters.find((f) => f.name === filterName);
    if (!found) return;
    setActiveFilter(filterName);
    setAdvancedFilters(found.filters);
    setSearchTerm("");
    setPage(1);
  };

  // Persist filter state to localStorage
  useEffect(() => {
    const stateToSave = {
      activeFilter,
      searchTerm,
      advancedFilters,
      page,
      order,
      orderBy,
    };
    localStorage.setItem("CasesListPageState", JSON.stringify(stateToSave));
  }, [activeFilter, searchTerm, advancedFilters, page, order, orderBy]);

  // Fetch cases if none
  useEffect(() => {
    if (cases.length === 0) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(fetchCases(token)).unwrap();
          setSnackbarMessage("Cases loaded successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error: any) {
          console.error("Error fetching cases:", error);
          setSnackbarMessage("Failed to load cases.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
      fetchData();
    }
  }, [cases.length, dispatch, getAccessTokenSilently]);

  // Filter logic
  const filterCases = useCallback(() => {
    let filteredData = [...cases];

    // If advanced filters exist, apply them
    if (advancedFilters && !areAdvancedFiltersEmpty(advancedFilters)) {
      filteredData = applyAdvancedFilters(filteredData, advancedFilters);
    } else {
      // Otherwise apply the quick filter logic
      if (activeFilter === "Escalated") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status !== "Auto Closed" &&
            caseItem.conclusion !== "Benign" &&
            caseItem.conclusion !== "Malicious_Benign"
        );
      } else if (activeFilter === "Benign") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status === "Auto Closed" ||
            caseItem.conclusion === "Benign" ||
            caseItem.conclusion === "Malicious_Benign"
        );
      }
    }

    if (searchTerm.trim() !== "") {
      const term = searchTerm.toLowerCase();
      filteredData = filteredData.filter((caseItem) =>
        Object.values(caseItem).some((val) =>
          val?.toString().toLowerCase().includes(term)
        )
      );
    }

    setFilteredCases(filteredData);
    setPage(1);
  }, [cases, activeFilter, searchTerm, advancedFilters]);

  useEffect(() => {
    filterCases();
  }, [filterCases]);

  const uniqueConclusions = Array.from(
    new Set(cases.map((c) => c.conclusion).filter(Boolean))
  );
  const uniqueStatuses = Array.from(
    new Set(cases.map((c) => c.status).filter(Boolean))
  );
  const uniqueSeverities = Array.from(
    new Set(cases.map((c) => c.severity).filter(Boolean))
  );

  function applyAdvancedFilters(casesToFilter: Case[], filters: any): Case[] {
    const {
      conclusions,
      statuses,
      severities,
      createdAt,
      alertTimestamp,
      identities,
    } = filters;

    let filtered = [...casesToFilter];

    if (conclusions.length > 0) {
      filtered = filtered.filter((caseItem) =>
        conclusions.includes(caseItem.conclusion)
      );
    }

    if (statuses.length > 0) {
      filtered = filtered.filter((caseItem) =>
        statuses.includes(caseItem.status)
      );
    }

    if (severities.length > 0) {
      filtered = filtered.filter((caseItem) =>
        severities.includes(caseItem.severity)
      );
    }

    if (createdAt[0] || createdAt[1]) {
      filtered = filtered.filter((caseItem) => {
        const caseDate = dayjs(caseItem.createdAt);
        const [start, end] = createdAt;
        if (start && caseDate.isBefore(dayjs(start), "day")) {
          return false;
        }
        if (end && caseDate.isAfter(dayjs(end), "day")) {
          return false;
        }
        return true;
      });
    }

    if (alertTimestamp[0] || alertTimestamp[1]) {
      filtered = filtered.filter((caseItem) => {
        if (!caseItem.alertTimestamp) return false;
        const alertDate = dayjs(caseItem.alertTimestamp);
        const [alertStart, alertEnd] = alertTimestamp;
        if (alertStart && alertDate.isBefore(dayjs(alertStart), "day")) {
          return false;
        }
        if (alertEnd && alertDate.isAfter(dayjs(alertEnd), "day")) {
          return false;
        }
        return true;
      });
    }

    if (identities.length > 0) {
      filtered = filtered.filter((caseItem) => {
        const caseIdentity = caseItem.identity
          ? caseItem.identity.toLowerCase()
          : "";
        return identities.some((identity: string) =>
          caseIdentity.includes(identity.toLowerCase())
        );
      });
    }

    return filtered;
  }

  const handleApplyFilters = (filters: any) => {
    setSearchTerm("");
    setActiveFilter("all");
    setAdvancedFilters(filters);
    setPage(1);
  };

  const handleRefresh = async () => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(fetchCases(token)).unwrap();
      setSnackbarMessage("Cases refreshed successfully!");
      setSnackbarSeverity("success");
    } catch (error: any) {
      console.error("Error refreshing cases:", error);
      setSnackbarMessage("Failed to refresh cases.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const recheckProcessing = async () => {
      try {
        const token = await getAccessTokenSilently();
        for (const item of uploads) {
          if (item.status === "processing") {
            await pollCaseStatus(item.id, token);
          }
        }
      } catch (error) {
        console.error("Error rechecking items:", error);
      }
    };

    recheckProcessing();
  }, [getAccessTokenSilently, pollCaseStatus, uploads]);

  if (loading && cases.length === 0) return <LoadingSkeleton />;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: "100%",
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(1),
        },
      }}
    >
      <TopBar
        totalCases={cases.length}
        onRefresh={handleRefresh}
        loading={loading}
        isAnyProcessing={uploads.some((u) => u.status === "processing")}
        showCheck={showCheck}
        uploads={uploads}
        onUploadEmailClick={handleOpenUploadModal}
      />

      <FiltersSection
        cases={cases}
        setFilteredCases={setFilteredCases}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        uniqueConclusions={uniqueConclusions}
        uniqueStatuses={uniqueStatuses}
        uniqueSeverities={uniqueSeverities}
        applyFilters={handleApplyFilters}
        setPage={setPage}
        advancedFilters={advancedFilters}
        setAdvancedFilters={setAdvancedFilters}
        customFilters={customFilters}
        onSaveCustomFilter={handleSaveCustomFilter}
        onApplyCustomFilter={applyCustomFilterByName}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />

      {/* NEW Enhanced Table */}
      <EnhancedCasesTable
        cases={filteredCases}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />

      <PaginationControls
        totalCases={filteredCases.length}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />

      <UploadEmailModal
        open={isUploadModalOpen}
        onClose={handleCloseUploadModal}
        addUploadItem={addUploadItem}
        markUploadReady={markUploadReady}
        markUploadError={markUploadError}
        pollCaseStatus={pollCaseStatus}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
          {/* Show link if it's a "ready item" message */}
          {snackbarMessage.includes("Analysis complete for") && (
            <RouterLink
              to={`/cases/${
                uploads.filter((u) => u.status === "ready").slice(-1)[0]?.id
              }`}
              style={{
                marginLeft: 8,
                textDecoration: "underline",
                color: "inherit",
              }}
            >
              View Case
            </RouterLink>
          )}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CasesListPage;
