import React, { useState } from "react";
import {
  Box,
  Typography,
  Badge,
  IconButton,
  Tooltip,
  CircularProgress,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Upload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { getRefreshButtonStyles } from "../../../utils/themeStyles";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { UploadItem } from "../../upload/components/UploadManager";

interface TopBarProps {
  totalCases: number;
  onRefresh: () => void;
  loading: boolean;
  isAnyProcessing: boolean;
  showCheck: boolean;
  uploads: UploadItem[];
  onUploadEmailClick: () => void;
}

const TopBar: React.FC<TopBarProps> = ({
  totalCases,
  onRefresh,
  loading,
  isAnyProcessing,
  showCheck,
  uploads,
  onUploadEmailClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Decide which icon to show
  let UploadButtonIcon: React.ReactNode = <UploadIcon />;
  if (isAnyProcessing) {
    UploadButtonIcon = <CircularProgress size={24} />;
  } else if (!isAnyProcessing && showCheck) {
    UploadButtonIcon = <CheckCircleIcon color="success" />;
  }

  // Menu open/close
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // A helper to pick a text color for the status
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "ready":
        return theme.palette.success.main; // green
      case "error":
        return theme.palette.error.main; // red
      default:
        return theme.palette.text.primary; // normal color
    }
  };

  const recentUploads = uploads.slice().reverse().slice(0, 10);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "none",
        marginBottom: theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ marginRight: theme.spacing(1) }}
        >
          Cases
        </Typography>
        <Badge badgeContent={totalCases} color="primary" max={999999999} />
      </Box>

      <Box>
        <Tooltip title={loading ? "Refreshing..." : "Refresh Cases"}>
          <span>
            <IconButton
              onClick={onRefresh}
              disabled={loading}
              sx={getRefreshButtonStyles(theme)}
            >
              {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
            </IconButton>
          </span>
        </Tooltip>

        {/* Single icon for both Upload + History */}
        <Tooltip title="Upload Email or View History">
          <span>
            <IconButton
              onClick={handleMenuOpen}
              disabled={loading}
              sx={getRefreshButtonStyles(theme)}
            >
              {UploadButtonIcon}
            </IconButton>
          </span>
        </Tooltip>

        {/* The menu listing previous uploads + "Upload New Email" */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              width: "max-content",
              maxHeight: 600,
              overflowY: "auto",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              onUploadEmailClick(); // open the modal
            }}
          >
            <strong>Upload New Email</strong>
          </MenuItem>
          <Divider />

          {recentUploads.length === 0 ? (
            <MenuItem disabled>No uploads yet</MenuItem>
          ) : (
            recentUploads.map((item) => {
              const createdDate = dayjs(item.createdAt).format(
                "YYYY-MM-DD HH:mm"
              );
              const disabledItem = item.status.toLowerCase() === "error";
              const handleClick = () => {
                if (!disabledItem) {
                  navigate(`/cases/${item.id}`);
                }
                handleMenuClose();
              };

              return (
                <MenuItem
                  key={item.id}
                  onClick={handleClick}
                  disabled={disabledItem}
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        sx={{ color: getStatusColor(item.status) }}
                      >
                        {item.fileName} - {item.status.toUpperCase()}
                      </Typography>
                    }
                    secondary={`Uploaded: ${createdDate}`}
                  />
                </MenuItem>
              );
            })
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default TopBar;
