import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
  Box,
  IconButton,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";

// dnd-kit
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Your app's types & sub-components
import { Case } from "../features/cases/types";
import ConclusionIndicator from "../features/cases/components/ConclusionIndicator";
import IStepsDisplay from "../features/cases/components/IStepsDisplay";
import FeedbackDisplay from "../features/cases/components/FeedbackDisplay";
import SeverityIndicatorDisplay from "../features/cases/components/SeverityIndicatorDisplay";
import AlertTimeDisplay from "../features/cases/components/AlertTimeDisplay";
import IdentityDisplay from "../features/cases/components/IdentityDisplay";
import DeviceChip from "../common/components/DeviceChip";
import AttackSurfaceDisplay from "../features/cases/components/AttackSurfaceDisplay";
import CaseTitleTooltip from "../features/cases/components/CasesTitleTooltip";

/** Column config for each column. */
interface ColumnConfig {
  key: keyof Case | "analyses";
  label: string;
  visible: boolean;
  sortable: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

/** Props from the parent page. */
interface EnhancedCasesTableProps {
  cases: Case[];
  page: number;
  rowsPerPage: number;
  order: "asc" | "desc" | undefined;
  orderBy: keyof Case | undefined;
  setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>>;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Case | undefined>>;
}

/** Default columns (iSteps & MITRE not sortable). */
const DEFAULT_COLUMNS: ColumnConfig[] = [
  { key: "caseId", label: "Name", visible: true, sortable: true },
  { key: "conclusion", label: "Conclusion", visible: true, sortable: true },
  { key: "analyses", label: "iSteps", visible: true, sortable: false },
  { key: "feedback", label: "Feedback", visible: true, sortable: true },
  { key: "severity", label: "Severity", visible: true, sortable: true },
  { key: "createdAt", label: "Alert Rcvd", visible: true, sortable: true },
  { key: "alertTimestamp", label: "Alert Time", visible: true, sortable: true },
  { key: "identity", label: "Identity", visible: true, sortable: true },
  { key: "device", label: "Device", visible: true, sortable: true },
  {
    key: "attackSurface",
    label: "Attack Surface",
    visible: true,
    sortable: true,
  },
  { key: "mitreStage", label: "MITRE", visible: true, sortable: false },
];

/** Local storage hook for columns. */
function usePersistedColumns(storageKey: string, defaultCols: ColumnConfig[]) {
  const [columns, setColumns] = useState<ColumnConfig[]>(() => {
    const saved = localStorage.getItem(storageKey);
    if (saved) {
      try {
        return JSON.parse(saved);
      } catch {
        // fallback
      }
    }
    return defaultCols;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(columns));
  }, [columns, storageKey]);

  return [columns, setColumns] as const;
}

/** Single header cell, draggable + resizable + clickable to sort. */
function SortableHeaderCell({
  col,
  order,
  orderBy,
  onSort,
  visibleIndex,
  onResize,
}: {
  col: ColumnConfig;
  order: "asc" | "desc" | undefined;
  orderBy: keyof Case | undefined;
  onSort: (colKey: keyof Case | "analyses") => void;
  visibleIndex: number;
  onResize: (e: React.MouseEvent<HTMLDivElement>, visibleIdx: number) => void;
}) {
  const theme = useTheme();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: String(col.key) });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 999 : "auto",
    cursor: "grab",
  };

  const isSorted = orderBy === col.key;
  const showSortArrow = col.sortable && isSorted;

  const isLightMode = theme.palette.mode === "light";
  const headerCellStyles = {
    padding: "10px 6px",
    backgroundColor: isLightMode
      ? theme.palette.common.white
      : theme.palette.background.default,
    color: theme.palette.grey[500],
    fontWeight: "bold",
    borderBottom: isLightMode
      ? `2px solid ${theme.palette.grey[200]}`
      : `2px solid ${theme.palette.grey[400]}`,
    width: col.width ?? 120,
    whiteSpace: "nowrap",
  } as const;

  // For smaller screens
  const tableTextSx = {
    fontSize: "0.875rem",
    [theme.breakpoints.down("xl")]: {
      lineHeight: 1.3,
      fontSize: "0.65rem",
    },
  };

  const handleClick = () => {
    if (col.sortable) {
      onSort(col.key);
    }
  };

  return (
    <TableCell
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...headerCellStyles, ...style }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* If sortable => TableSortLabel, else plain text. */}
        {col.sortable ? (
          <TableSortLabel
            active={showSortArrow}
            direction={isSorted ? order || "asc" : "asc"}
            onClick={handleClick}
          >
            {col.label}
          </TableSortLabel>
        ) : (
          <Typography
            onClick={handleClick}
            component="span"
            sx={{ fontSize: tableTextSx, fontWeight: "bold" }}
          >
            {col.label}
          </Typography>
        )}

        {/* 4px wide resizing handle */}
        <Box
          onMouseDown={(e) => onResize(e, visibleIndex)}
          sx={{
            width: "4px",
            cursor: "col-resize",
            height: "100%",
            ml: 0.5,
          }}
        />
      </Box>
    </TableCell>
  );
}

/** The main table: drag columns, show/hide, resize, single-click sort. */
const EnhancedCasesTable: React.FC<EnhancedCasesTableProps> = ({
  cases,
  page,
  rowsPerPage,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const [columns, setColumns] = usePersistedColumns(
    "MyTableColumns",
    DEFAULT_COLUMNS
  );

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(e.currentTarget);
  const handleCloseMenu = () => setMenuAnchor(null);

  const handleToggleVisibility = (key: string) => {
    setColumns((prev) =>
      prev.map((col) =>
        col.key === key ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // "Show All" / "Hide All"
  const allVisible = columns.every((col) => col.visible);

  // Sorting fallback
  function getConclusionOrder(conclusion: string) {
    switch (conclusion) {
      case "Malicious":
        return 3;
      case "Suspicious":
        return 2;
      case "Benign":
      case "Malicious_Benign":
        return 1;
      default:
        return 0;
    }
  }
  function getSeverityOrder(severity: string) {
    switch (severity) {
      case "Critical":
        return 4;
      case "High":
        return 3;
      case "Medium":
        return 2;
      case "Low":
        return 1;
      default:
        return 0;
    }
  }

  const handleSort = (colKey: keyof Case | "analyses") => {
    if (colKey === "analyses") return;
    const isAsc = orderBy === colKey && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(colKey as keyof Case);
  };

  // dnd-kit: pointer sensor with a ~15px threshold
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 15 },
  });
  const sensors = useSensors(pointerSensor);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (!over || active.id === over.id) return;

    setColumns((prevCols) => {
      const visibleCols = prevCols.filter((c) => c.visible);
      const hiddenCols = prevCols.filter((c) => !c.visible);

      const oldIndex = visibleCols.findIndex(
        (c) => String(c.key) === active.id
      );
      const newIndex = visibleCols.findIndex((c) => String(c.key) === over.id);
      if (oldIndex === -1 || newIndex === -1) return prevCols;

      // reorder the visible subset
      const reordered = arrayMove(visibleCols, oldIndex, newIndex);

      // re-merge hidden in original position
      const finalArr: ColumnConfig[] = [];
      let vIndex = 0;
      for (const col of prevCols) {
        if (!col.visible) {
          finalArr.push(col);
        } else {
          finalArr.push(reordered[vIndex]);
          vIndex++;
        }
      }
      return finalArr;
    });
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement>,
    visibleIndex: number
  ) => {
    e.preventDefault();
    const startX = e.clientX;

    const visibleCols = columns.filter((c) => c.visible);
    const startWidth = visibleCols[visibleIndex].width ?? 120;

    const handleMouseMove = (moveEvt: MouseEvent) => {
      const deltaX = moveEvt.clientX - startX;
      setColumns((prev) => {
        const vCols = prev.filter((c) => c.visible);
        const hCols = prev.filter((c) => !c.visible);

        const updated = [...vCols];
        updated[visibleIndex] = {
          ...updated[visibleIndex],
          width: Math.max(50, startWidth + deltaX),
        };

        // merge hidden
        const merged: ColumnConfig[] = [];
        let idx = 0;
        for (const c of prev) {
          if (!c.visible) merged.push(c);
          else {
            merged.push(updated[idx]);
            idx++;
          }
        }
        return merged;
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // Sort data
  const sortedCases = React.useMemo(() => {
    if (!orderBy) return cases.slice();
    const cloned = cases.slice();
    cloned.sort((a, b) => {
      const aVal = (a as any)[orderBy];
      const bVal = (b as any)[orderBy];
      if (aVal == null && bVal != null) return order === "asc" ? -1 : 1;
      if (aVal != null && bVal == null) return order === "asc" ? 1 : -1;
      if (aVal == null && bVal == null) return 0;
      if (aVal < bVal) return order === "asc" ? -1 : 1;
      if (aVal > bVal) return order === "asc" ? 1 : -1;

      // fallback to conclusion, date, severity
      const concDiff =
        getConclusionOrder(a.conclusion) - getConclusionOrder(b.conclusion);
      if (concDiff !== 0) return -concDiff;

      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      if (dateA !== dateB) return dateB - dateA;

      const sevDiff =
        getSeverityOrder(a.severity) - getSeverityOrder(b.severity);
      return -sevDiff;
    });
    return cloned;
  }, [cases, order, orderBy]);

  // Pagination
  const startIdx = (page - 1) * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  const paginatedCases = sortedCases.slice(startIdx, endIdx);

  // Body cell styling
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  const bodyCellStyles = {
    padding: "20px 6px",
    borderBottom: "none",
    color: theme.palette.text.primary,
  } as const;

  const tableTextSx = {
    fontSize: "0.875rem",
    [theme.breakpoints.down("xl")]: {
      lineHeight: 1.3,
      fontSize: "0.65rem",
    },
  };

  /**
   * Renders the table cell content for each column in the correct order.
   */
  const renderCell = useCallback(
    (caseItem: Case, col: ColumnConfig) => {
      switch (col.key) {
        case "caseId":
          return (
            <CaseTitleTooltip
              caseItem={caseItem}
              filteredCases={paginatedCases}
              handleCopyId={(id) => navigator.clipboard.writeText(id)}
              textSx={tableTextSx}
            />
          );

        case "conclusion":
          return (
            <ConclusionIndicator
              conclusion={caseItem.conclusion}
              textSx={tableTextSx}
            />
          );

        case "analyses":
          return <IStepsDisplay analyses={caseItem.analyses} />;

        case "feedback":
          // Check if "agree" or "disagree" => show FeedbackDisplay
          if (
            caseItem.feedback &&
            ["agree", "disagree"].includes(caseItem.feedback.toLowerCase())
          ) {
            return (
              <FeedbackDisplay
                feedback={caseItem.feedback}
                reason={caseItem.feedbackReason}
                textSx={tableTextSx}
              />
            );
          }
          return <Typography variant="body2">{caseItem.feedback}</Typography>;

        case "severity":
          return (
            <SeverityIndicatorDisplay
              severity={caseItem.severity}
              textSx={tableTextSx}
            />
          );

        case "createdAt":
          return (
            <AlertTimeDisplay
              timestamp={caseItem.createdAt}
              textSx={tableTextSx}
            />
          );

        case "alertTimestamp":
          return (
            <AlertTimeDisplay
              timestamp={caseItem.alertTimestamp}
              textSx={tableTextSx}
            />
          );

        case "identity":
          return (
            <IdentityDisplay
              identity={caseItem.identity}
              textSx={tableTextSx}
            />
          );

        case "device":
          return <DeviceChip device={caseItem.device} textSx={tableTextSx} />;

        case "attackSurface":
          return (
            <AttackSurfaceDisplay
              caseType={caseItem.attackSurface}
              textSx={tableTextSx}
            />
          );

        case "mitreStage":
          return <>{caseItem.mitreStage}</>;

        default:
          return (caseItem as any)[col.key];
      }
    },
    [paginatedCases, tableTextSx]
  );

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "auto" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
        <Table
          stickyHeader
          sx={{
            "& thead th": {
              fontSize: "0.8rem",
              fontWeight: "bold",
              [theme.breakpoints.down("xl")]: {
                fontSize: tableTextSx,
              },
            },
            "& tbody td": {
              fontSize: tableTextSx,
              [theme.breakpoints.down("xl")]: {
                fontSize: tableTextSx,
              },
            },
          }}
        >
          {/* DnD context for header reordering */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={columns.filter((c) => c.visible).map((c) => String(c.key))}
              strategy={horizontalListSortingStrategy}
            >
              {/* -------- TABLE HEAD -------- */}
              <TableHead>
                <TableRow>
                  {/* Map the visible columns in the stored order */}
                  {columns
                    .filter((c) => c.visible)
                    .map((col, index) => (
                      <SortableHeaderCell
                        key={col.key}
                        col={col}
                        order={order}
                        orderBy={orderBy}
                        visibleIndex={index}
                        onSort={handleSort}
                        onResize={handleMouseDown}
                      />
                    ))}

                  {/* Show/hide columns menu on the far right */}
                  <TableCell
                    sx={{
                      width: 32,
                      minWidth: 32,
                      maxWidth: 32,
                      padding: "0px 0px",
                      backgroundColor: isLightMode
                        ? theme.palette.common.white
                        : theme.palette.background.default,
                      color: theme.palette.grey[500],
                      fontWeight: "bold",
                      borderBottom: isLightMode
                        ? `2px solid ${theme.palette.grey[200]}`
                        : `2px solid ${theme.palette.grey[400]}`,
                      textAlign: "center",
                    }}
                  >
                    <IconButton onClick={handleOpenMenu} size="small">
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={handleCloseMenu}
                    >
                      {/* Toggle "Show All" / "Hide All" */}
                      <MenuItem
                        onClick={() => {
                          const allVisible = columns.every(
                            (col) => col.visible
                          );
                          setColumns((prev) =>
                            prev.map((col) => ({
                              ...col,
                              visible: !allVisible,
                            }))
                          );
                        }}
                      >
                        <Checkbox
                          checked={allVisible}
                          indeterminate={
                            !columns.every((col) => col.visible) &&
                            !columns.every((col) => !col.visible)
                          }
                          color="secondary"
                        />
                        <Typography>
                          {allVisible ? "Hide All" : "Show All"}
                        </Typography>
                      </MenuItem>

                      {/* One menu item per column */}
                      {columns.map((col) => (
                        <MenuItem key={String(col.key)}>
                          <Checkbox
                            checked={col.visible}
                            onChange={() =>
                              handleToggleVisibility(String(col.key))
                            }
                          />
                          <Typography>{col.label}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
            </SortableContext>
          </DndContext>

          {/* -------- TABLE BODY -------- */}
          <TableBody>
            {paginatedCases.map((caseItem) => (
              <TableRow
                key={caseItem.caseId}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                {/* Map the same visible columns in order -> reorder is applied */}
                {columns
                  .filter((c) => c.visible)
                  .map((col) => (
                    <TableCell
                      key={col.key}
                      sx={{
                        ...bodyCellStyles,
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        overflow: "hidden",
                      }}
                      style={{
                        width: col.width ?? 120,
                        minWidth: col.minWidth,
                        maxWidth: col.maxWidth,
                      }}
                    >
                      {renderCell(caseItem, col)}
                    </TableCell>
                  ))}

                {/* blank cell for alignment with the MoreVert column */}
                <TableCell
                  sx={{
                    width: 32,
                    minWidth: 32,
                    maxWidth: 32,
                    padding: "20px 6px",
                    borderBottom: "none",
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EnhancedCasesTable;
