export const formatDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString("en-US");
};

// Utility function to format date to 'YYYY-MM-DD'
export const formatAlertDate = (timestamp: string) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return timestamp; // Return the original value if it's not a valid date
  }
  return date.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
};

export function toShortDate(isoDateTime: string): string {
  if (!isoDateTime) return "";
  try {
    // Create a Date from the ISO string
    const date = new Date(isoDateTime);
    // Convert to YYYY-MM-DD using toISOString + split
    return date.toISOString().split("T")[0];
  } catch (err) {
    // Fallback: if something fails, just return the original or an empty string
    console.error("Invalid date:", isoDateTime, err);
    return isoDateTime;
  }
}
