import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import YearlyTimeSaved from "../../../assets/time-saved-icon.svg";
import DollarsSaved from "../../../assets/yr-dollars-saved-icon.svg";
import SystemHealth from "../../../assets/system-health-icon.svg";

// Utility to format numbers with commas
const formatNumber = (value: string | number): string => {
  if (typeof value === "number") {
    return value.toLocaleString(); // Adds commas to numbers
  }
  return value.toString();
};

interface MetricCardProps {
  title: string;
  value: string | number;
  subtitle?: string; // New optional subtitle for additional details
  icon?: React.ReactNode;
  showHealthLine?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  subtitle,
  icon,
  showHealthLine = false,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: icon ? "row" : "column",
        alignItems: "center",
        justifyContent: icon ? "flex-start" : "flex-start",
        textAlign: icon ? "left" : "center",
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        // Decrease padding at narrower breakpoints
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(2),
          gap: theme.spacing(1.5),
        },
      }}
    >
      {icon && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            flexShrink: 0,
            // Shrink icon container a bit at narrower widths
            [theme.breakpoints.down("xl")]: {
              width: "45px",
              height: "45px",
            },
          }}
        >
          {icon}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: icon ? "flex-start" : "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Typography
          variant="body2"
          color={theme.palette.grey[500]}
          sx={{
            fontSize: "0.85rem",
            fontWeight: 400,
            // Optionally shrink slightly at narrower breakpoints
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.7rem",
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          sx={{
            fontSize: "1.60rem",
            fontWeight: 700,
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
            // Shrink the main value at narrower breakpoints
            [theme.breakpoints.down("xl")]: {
              fontSize: "1.2rem",
            },
          }}
        >
          {formatNumber(value)}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            color={theme.palette.grey[500]}
            sx={{
              fontSize: "0.75rem", // Smaller font size for subtitle
            }}
          >
            {subtitle}
          </Typography>
        )}
        {showHealthLine && (
          <Box
            sx={{
              height: "4px",
              width: "100px",
              backgroundColor: theme.palette.success.main,
              borderRadius: "2px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

interface DashboardHeaderProps {
  metrics: {
    timeSaved: string;
    yearlyDollarsSaved: string;
    systemHealth: string;
    evidenceCollected: number;
    denoisedAlerts: number;
    unreviewedMaliciousAlerts: number;
  };
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ metrics }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(1),
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: theme.spacing(2),
          [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
            gap: theme.spacing(1),
          },
        }}
      >
        <MetricCard
          title="Time Saved"
          value={metrics.timeSaved}
          subtitle={`(monthly)`} // Monthly time saved
          icon={<img src={YearlyTimeSaved} alt="time saved icon" />}
        />
        <MetricCard
          title="Yr. Dollars Saved"
          value={metrics.yearlyDollarsSaved}
          subtitle={`(projected)`} // Projected yearly savings
          icon={<img src={DollarsSaved} alt="Dollars saved icon" />}
        />
        <MetricCard
          title="System Health"
          value={metrics.systemHealth}
          subtitle={`  `}
          icon={<img src={SystemHealth} alt="System health icon" />}
          showHealthLine // Enable the horizontal green line for this card
        />
        <MetricCard
          title="Total Alerts"
          value={metrics.evidenceCollected}
          subtitle={`  `}
        />
        <MetricCard
          title="Denoised %"
          value={`${metrics.denoisedAlerts}%`} // Append '%' to the value
          subtitle={`  `}
        />
        <MetricCard
          title="Unreviewed Malicious Alerts "
          value={metrics.unreviewedMaliciousAlerts}
          subtitle={`  `}
        />
      </Box>
    </Paper>
  );
};

export default DashboardHeader;
