import React from "react";
import { Box, Paper, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DashboardHeaderSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(1),
          marginBottom: theme.spacing(2),
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: theme.spacing(2),
          [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
            gap: theme.spacing(1),
          },
        }}
      >
        {[...Array(6)].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: theme.spacing(2),
              padding: theme.spacing(1),
              [theme.breakpoints.down("xl")]: {
                gap: theme.spacing(1),
                padding: theme.spacing(0.5),
              },
            }}
          >
            <Skeleton variant="circular" width={50} height={50} />
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Skeleton variant="text" width="60%" height={20} />
              <Skeleton variant="text" width="80%" height={30} />
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default DashboardHeaderSkeleton;
