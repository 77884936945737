import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Typography,
  Popover,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";

interface FeedbackSectionProps {
  feedback: string | null;
  feedbackReason: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  isEditingFeedback: boolean;
  feedbackOption: string;
  feedbackText: string;
  onFeedbackOptionChange: (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => void;
  onFeedbackTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFeedback: () => void;
  onAgree: (position: "agree", reason: string) => void;
  onDisagree: (position: "disagree", reason: string) => void;
  onEditFeedback: () => void;
  onCancelEdit: () => void;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>;
  setFeedbackOption: React.Dispatch<React.SetStateAction<string>>;
}

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  feedback,
  feedbackReason,
  feedbackLoading,
  feedbackError,
  isEditingFeedback,
  feedbackOption,
  feedbackText,
  onFeedbackOptionChange,
  onFeedbackTextChange,
  onSubmitFeedback,
  onAgree,
  onDisagree,
  onEditFeedback,
  onCancelEdit,
  setFeedbackText,
  setFeedbackOption,
}) => {
  const theme = useTheme();

  // State for Popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoIconClose = () => {
    setAnchorEl(null);
  };

  // Validation state (only required if "disagree")
  const [errorText, setErrorText] = useState<string | null>(null);

  // States for when no existing feedback yet
  const [isDisagreeSelected, setIsDisagreeSelected] = useState(false);
  const [isAgreeSelected, setIsAgreeSelected] = useState(false);

  const handleNewDisagreeClick = () => {
    setFeedbackText("");
    setErrorText(null);
    setIsDisagreeSelected(true);
    setFeedbackOption("disagree");
  };

  const handleNewDisagreeSubmit = () => {
    onDisagree("disagree", feedbackText);
    setIsDisagreeSelected(false);
  };

  const handleAgreeClick = () => {
    setFeedbackText("");
    setErrorText(null);
    setIsAgreeSelected(true);
    setFeedbackOption("agree");
  };

  const handleNewAgreeSubmit = () => {
    onAgree("agree", feedbackText);
    setIsAgreeSelected(false);
  };

  const handleFeedbackTextChangeLocal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    onFeedbackTextChange(event);

    // Validate only if "disagree"
    if (feedbackOption.toLowerCase() === "disagree") {
      if (value.trim().length < 5) {
        setErrorText("Reason must be at least 5 characters long.");
      } else {
        setErrorText(null);
      }
    } else {
      setErrorText(null);
    }
  };

  const isDisagreeSubmitDisabled =
    feedbackLoading ||
    (feedbackOption.toLowerCase() === "disagree" &&
      feedbackText.trim().length < 5 &&
      (isDisagreeSelected || isEditingFeedback));

  return (
    <Box>
      {feedback && !isEditingFeedback ? (
        // Display existing feedback as a single thumb icon, with colored background
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="View Feedback" arrow>
            <IconButton
              onClick={handleIconClick}
              sx={{
                backgroundColor:
                  feedback.toLowerCase() === "agree"
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                color: "#fff", // icon color
                "&:hover": {
                  backgroundColor:
                    feedback.toLowerCase() === "agree"
                      ? theme.palette.success.dark
                      : theme.palette.error.dark,
                },
              }}
            >
              {feedback.toLowerCase() === "agree" ? (
                <ThumbUpIcon />
              ) : (
                <ThumbDownIcon />
              )}
            </IconButton>
          </Tooltip>

          {/* Popover shows feedback reason, if any */}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleInfoIconClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 2, minWidth: 300 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                User Provided Feedback:
              </Typography>
              <Typography variant="body1">
                {feedbackReason && feedbackReason.trim().length > 0
                  ? feedbackReason
                  : "None"}
              </Typography>
            </Box>
          </Popover>

          {/* Edit button to re-enter editing mode */}
          <Tooltip title="Edit" arrow>
            <IconButton
              onClick={onEditFeedback}
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : isEditingFeedback ? (
        // Editing existing feedback
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            value={feedbackOption}
            exclusive
            onChange={onFeedbackOptionChange}
            aria-label="feedback option"
          >
            <ToggleButton
              value="agree"
              aria-label="agree"
              sx={{
                color: theme.palette.text.primary,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.success.main,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: theme.palette.success.dark,
                  },
                },
              }}
            >
              <ThumbUpIcon sx={{ marginRight: theme.spacing(1) }} />
              <span>Agree</span>
            </ToggleButton>
            <ToggleButton
              value="disagree"
              aria-label="disagree"
              sx={{
                color: theme.palette.text.primary,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.error.main,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: theme.palette.error.dark,
                  },
                },
              }}
            >
              <ThumbDownIcon sx={{ marginRight: theme.spacing(1) }} />
              <span>Disagree</span>
            </ToggleButton>
          </ToggleButtonGroup>

          {feedbackOption.toLowerCase() === "disagree" ? (
            <TextField
              label="Reason"
              variant="outlined"
              value={feedbackText}
              onChange={handleFeedbackTextChangeLocal}
              fullWidth
              required
              error={!!errorText}
              helperText={errorText}
              InputLabelProps={{
                style: { color: theme.palette.info.main },
              }}
              sx={{
                width: "100%",
                [theme.breakpoints.down("xl")]: {
                  maxWidth: 500,
                },
              }}
            />
          ) : feedbackOption.toLowerCase() === "agree" ? (
            <TextField
              label="Comment (Optional)"
              variant="outlined"
              value={feedbackText}
              onChange={handleFeedbackTextChangeLocal}
              fullWidth
              InputLabelProps={{
                style: { color: theme.palette.info.main },
              }}
              sx={{
                width: "100%",
                [theme.breakpoints.down("xl")]: {
                  maxWidth: 500,
                },
              }}
            />
          ) : null}

          <Button
            variant="contained"
            onClick={onSubmitFeedback}
            disabled={
              feedbackLoading ||
              (feedbackOption.toLowerCase() === "disagree" && !!errorText)
            }
            sx={{
              backgroundColor: theme.palette.info.main,
              color: "#fff",
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="text"
            onClick={onCancelEdit}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : isDisagreeSelected ? (
        // No existing feedback, user clicked "DISAGREE"
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <TextField
            label="Reason"
            variant="outlined"
            value={feedbackText}
            size="small"
            onChange={handleFeedbackTextChangeLocal}
            fullWidth
            required
            error={!!errorText}
            helperText={errorText}
            InputLabelProps={{
              style: { color: theme.palette.info.main },
            }}
            sx={{
              width: "100%",
              [theme.breakpoints.down("xl")]: {
                maxWidth: 400,
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleNewDisagreeSubmit}
            disabled={isDisagreeSubmitDisabled}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: "#fff",
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="text"
            onClick={() => setIsDisagreeSelected(false)}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : isAgreeSelected ? (
        // No existing feedback, user clicked "AGREE"
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          {/* Optional comment for agree, no validation needed */}
          <TextField
            label="Comment (Optional)"
            size="small"
            variant="outlined"
            value={feedbackText}
            onChange={handleFeedbackTextChangeLocal}
            fullWidth
            InputLabelProps={{
              style: { color: theme.palette.info.main },
            }}
            sx={{
              width: "100%",
              [theme.breakpoints.down("xl")]: {
                maxWidth: 400,
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleNewAgreeSubmit}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: "#fff",
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="text"
            onClick={() => setIsAgreeSelected(false)}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        // Initial state: no existing feedback
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.success.main,
              marginRight: "1rem",
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
              "&:hover": {
                backgroundColor: theme.palette.success.dark,
              },
            }}
            onClick={handleAgreeClick}
            disabled={feedbackLoading}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "AGREE"
            )}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.error.main,
              marginRight: "1rem",
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
              "&:hover": {
                backgroundColor: theme.palette.error.dark,
              },
            }}
            onClick={handleNewDisagreeClick}
            disabled={feedbackLoading}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "DISAGREE"
            )}
          </Button>
        </Box>
      )}

      {feedbackError && (
        <Typography color="error" sx={{ marginTop: theme.spacing(1) }}>
          {feedbackError}
        </Typography>
      )}
    </Box>
  );
};

export default FeedbackSection;
