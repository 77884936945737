import { useEffect, useState } from "react";

export interface UploadItem {
  id: string; // TODO: Check the returned case ID or email_id from the server
  fileName: string;
  status: "processing" | "ready" | "error";
  error?: string;
  createdAt: number; // timestamp
}

export function useUploadManager() {
  // Load from localStorage
  const [uploads, setUploads] = useState<UploadItem[]>(() => {
    try {
      const stored = localStorage.getItem("uploadHistory");
      return stored ? JSON.parse(stored) : [];
    } catch (err) {
      console.error("Failed to parse localStorage uploadHistory:", err);
      return [];
    }
  });

  // Persist whenever `uploads` changes
  useEffect(() => {
    try {
      localStorage.setItem("uploadHistory", JSON.stringify(uploads));
    } catch (err) {
      console.error("Failed to save uploadHistory to localStorage:", err);
    }
  }, [uploads]);

  // Helper to add a new “processing” item
  function addUploadItem(id: string, fileName: string) {
    const newItem: UploadItem = {
      id,
      fileName,
      status: "processing",
      createdAt: Date.now(),
    };
    setUploads((prev) => [...prev, newItem]);
  }

  // Helper to mark an item "ready"
  function markUploadReady(id: string) {
    setUploads((prev) =>
      prev.map((item) => (item.id === id ? { ...item, status: "ready" } : item))
    );
  }

  // Helper to mark an item "error"
  function markUploadError(id: string, error: string) {
    setUploads((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, status: "error", error } : item
      )
    );
  }

  // Poll case status until done or error
  async function pollCaseStatus(id: string, token: string) {
    // TODO: Do we need to extend?
    const maxAttempts = 70;
    const delay = 30000; // ms (5s) between tries

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CASES_API_URL}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // Analysis complete
          markUploadReady(id);
          return; // done
        } else if (response.status === 202 || response.status === 404) {
          // Still processing; wait and continue
          await new Promise((res) => setTimeout(res, delay));
        } else {
          // Some other status code => error
          markUploadError(id, `Unexpected status: ${response.status}`);
          return;
        }
      } catch (err: any) {
        // fetch error
        markUploadError(id, err.message || "Polling failed");
        return;
      }
    }

    // If we exit loop, we timed out
    markUploadError(id, "Timed out waiting for analysis");
  }

  return {
    uploads,
    addUploadItem,
    markUploadReady,
    markUploadError,
    pollCaseStatus,
  };
}
