import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CaseTitleSectionProps {
  caseTitle: string;
  caseConclusion: string;
  onReprocessClick?: () => void;
  reprocessLoading?: boolean;
}

const CaseTitleSection: React.FC<CaseTitleSectionProps> = ({
  caseTitle,
  caseConclusion,
  onReprocessClick,
  reprocessLoading,
}) => {
  const theme = useTheme();

  let displayConclusion = caseConclusion;
  let color = theme.palette.success.main;

  // color logic...
  if (caseConclusion === "Malicious_Benign") {
    displayConclusion = "Malicious - No Action";
    color = theme.palette.warning.main;
  } else if (caseConclusion === "Malicious") {
    color = theme.palette.error.main;
  } else if (caseConclusion === "Suspicious") {
    color = theme.palette.info.main;
  } else if (caseConclusion === "Benign") {
    color = theme.palette.text.primary;
  }

  if (reprocessLoading) {
    // If we’re reprocessing, show a spinner  text
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <CircularProgress size={20} />
        <Typography variant="h5" sx={{ color: theme.palette.info.main }}>
          Reprocessing...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="h3"
        sx={{ color, cursor: "pointer" }}
        onClick={reprocessLoading ? undefined : onReprocessClick}
      >
        {displayConclusion}
      </Typography>
    </Box>
  );
};

export default CaseTitleSection;
