import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCaseDetail,
  fetchEmlData,
  submitFeedback,
  reprocessCase,
  startReprocessCase,
  pollReprocessCase,
  downloadAttachment,
  fetchArtifactPreview,
} from "../../../api/caseDetailsApi";
import { CaseDetails, Feedback } from "../../../types/caseDetailsTypes";

interface ErrorInfo {
  message: string;
  code?: number;
  details?: any;
}

interface CaseDetailState {
  caseDetail: CaseDetails | null;
  loading: boolean;
  error: string | ErrorInfo | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  emlData: string | null;
  emlLoading: boolean;
  emlError: string | null;
  isProcessing: boolean;
  reprocessLoading: boolean;
  reprocessError: string | null;
  attachmentDownloadLoading: boolean;
  attachmentDownloadError: string | null;
  previewLoading: boolean;
  previewError: string | null;
  previewUrl: string | null;
}

const initialState: CaseDetailState = {
  caseDetail: null,
  loading: false,
  error: null,
  feedbackLoading: false,
  feedbackError: null,
  emlData: null,
  emlLoading: false,
  emlError: null,
  isProcessing: false,
  reprocessLoading: false,
  reprocessError: null,
  attachmentDownloadLoading: false,
  attachmentDownloadError: null,
  previewLoading: false,
  previewError: null,
  previewUrl: null,
};

const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // (A) fetchCaseDetail
    builder
      .addCase(fetchCaseDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.feedbackError = null;
        state.isProcessing = false;
      })
      .addCase(fetchCaseDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        if (action.payload.httpStatus === 202) {
          // means STILL PROCESSING
          state.isProcessing = true;
          state.caseDetail = null;
        } else {
          // normal success (200)
          state.isProcessing = false;
          const { httpStatus, ...rest } = action.payload;
          state.caseDetail = rest as CaseDetails;
        }
      })
      .addCase(fetchCaseDetail.rejected, (state, action) => {
        state.loading = false;
        state.isProcessing = false;

        if (action.payload && typeof action.payload === "object") {
          // If we returned { message, code, details } from rejectWithValue
          state.error = action.payload as ErrorInfo;
        } else {
          // fallback to string
          state.error =
            (action.payload as string) ||
            (action.error && action.error.message) ||
            "Failed to fetch case detail";
        }
      });
    // (B) feedback
    builder
      .addCase(submitFeedback.pending, (state) => {
        state.feedbackLoading = true;
        state.feedbackError = null;
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.feedbackLoading = false;
        const { position, reason } = action.payload;
        if (state.caseDetail) {
          if (state.caseDetail.feedbacks?.length) {
            const feedbackEntry = state.caseDetail.feedbacks[0];
            feedbackEntry.result = position;
            feedbackEntry.text = reason;
          } else {
            // create a new feedback entry
            state.caseDetail.feedbacks = [
              {
                user: { name: state.caseDetail.analyst || "Unknown" },
                updatedAt: new Date().toISOString(),
                result: position,
                text: reason,
              },
            ];
          }
        }
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.feedbackLoading = false;
        state.feedbackError =
          (action.payload as string) ||
          action.error.message ||
          "Failed feedback";
      });

    // (C) EML
    builder
      .addCase(fetchEmlData.pending, (state) => {
        state.emlLoading = true;
        state.emlError = null;
      })
      .addCase(fetchEmlData.fulfilled, (state, action) => {
        state.emlLoading = false;
        state.emlData = action.payload;
      })
      .addCase(fetchEmlData.rejected, (state, action) => {
        state.emlLoading = false;
        state.emlError =
          (action.payload as string) || "Failed to fetch .eml data";
      });

    // (D) Reprocess
    builder
      .addCase(startReprocessCase.pending, (state) => {
        state.reprocessLoading = true;
        state.reprocessError = null;
      })
      .addCase(startReprocessCase.fulfilled, (state) => {
        state.reprocessLoading = true; // we keep it true until poll finishes
      })
      .addCase(startReprocessCase.rejected, (state, action) => {
        state.reprocessLoading = false;
        state.reprocessError =
          (action.payload as string) ||
          action.error.message ||
          "Reprocess fail";
      })
      .addCase(pollReprocessCase.pending, (state) => {
        state.reprocessLoading = true;
        state.reprocessError = null;
      })
      .addCase(pollReprocessCase.fulfilled, (state) => {
        state.reprocessLoading = false;
      })
      .addCase(pollReprocessCase.rejected, (state, action) => {
        state.reprocessLoading = false;
        state.reprocessError =
          (action.payload as string) || action.error.message || "Poll fail";
      });

    // (E) Download attachment
    builder
      .addCase(downloadAttachment.pending, (state) => {
        state.attachmentDownloadLoading = true;
        state.attachmentDownloadError = null;
      })
      .addCase(downloadAttachment.fulfilled, (state) => {
        state.attachmentDownloadLoading = false;
      })
      .addCase(downloadAttachment.rejected, (state, action) => {
        state.attachmentDownloadLoading = false;
        state.attachmentDownloadError =
          (action.payload as string) || action.error.message || "Download fail";
      });

    // (F) NEW: fetchArtifactPreview
    builder
      .addCase(fetchArtifactPreview.pending, (state) => {
        state.previewLoading = true;
        state.previewError = null;
        // Clear out any old preview
        state.previewUrl = null;
      })
      .addCase(fetchArtifactPreview.fulfilled, (state, action) => {
        state.previewLoading = false;
        // action.payload is the object URL
        state.previewUrl = action.payload;
      })
      .addCase(fetchArtifactPreview.rejected, (state, action) => {
        state.previewLoading = false;
        state.previewError =
          (action.payload as string) || "Failed to fetch artifact preview";
      });
  },
});

export default caseDetailSlice.reducer;
