import React from "react";
import { Chip, SxProps, Theme, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ConclusionIndicatorProps {
  conclusion: string; // "Benign" | "Suspicious" | "Malicious" | "Malicious_Benign" (ideally)
  textSx?: SxProps<Theme>;
}

const ConclusionIndicator: React.FC<ConclusionIndicatorProps> = ({
  conclusion,
  textSx,
}) => {
  const theme = useTheme();

  // Record of recognized conclusions
  const conclusionStyles: Record<
    string,
    { backgroundColor: string; color: string }
  > = {
    Benign: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    Suspicious: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
    },
    Malicious: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    Malicious_Benign: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
    },
  };

  // fallback style for anything unrecognized
  const fallbackStyle = {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  };

  // Grab the style if recognized, otherwise fallback
  const style = conclusionStyles[conclusion] || fallbackStyle;

  // Possibly show a more user-friendly label
  const displayLabel =
    conclusion === "Malicious_Benign" ? "Malicious - No Action" : conclusion;

  return (
    <Tooltip title={displayLabel}>
      <Chip
        label={displayLabel}
        size="small"
        sx={{
          backgroundColor: style.backgroundColor,
          color: style.color,
          fontWeight: "bold",
          border: `1px solid ${style.color}`,
          borderRadius: "8px",

          // Force a fixed width
          width: 110,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "center",
          paddingX: 1.5,
          fontSize: "0.75rem",
          height: "24px",

          [theme.breakpoints.down("xl")]: {
            width: 91,
            fontSize: "0.65rem",
            height: "20px",
          },
        }}
      />
    </Tooltip>
  );
};

export default ConclusionIndicator;
