import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CaseTitleSection from "./CaseTitleSection";
import CaseInfoSection from "./CaseInfoSection";
import FeedbackSection from "./FeedbackSection";
import CaseDetailsSection from "./CaseDetailsSection";
import KeyEvidenceSection from "./KeyEvidenceSection";
import DescriptionSection from "./DescriptionSection";
interface CaseSummarySectionProps {
  caseNumber: string;
  caseTitle: string;
  caseConclusion: string;
  keyIndicators: string[];
  createdOn: string;
  attackSurface: string;
  alertTime: string;
  analyst: string;
  analystAvatar: string;
  status: string;
  severity: string;
  device: string;
  from: string;
  description: string;
  feedback: string | null;
  feedbackReason: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  isEditingFeedback: boolean;
  feedbackOption: string;
  feedbackText: string;
  onFeedbackOptionChange: (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => void;
  onFeedbackTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFeedback: () => void;
  onAgree: (position: "agree", reason: string) => void;
  onDisagree: (position: "disagree", reason: string) => void;
  onEditFeedback: () => void;
  onCancelEdit: () => void;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>;
  setFeedbackOption: React.Dispatch<React.SetStateAction<string>>;
  onReprocessClick: (() => void) | undefined;
  reprocessLoading?: boolean;
}

const CaseSummarySection: React.FC<CaseSummarySectionProps> = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      {/* Top Row: Case Title, Device, From Address, Feedback */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        {/* Left side */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(4),
          }}
        >
          <CaseTitleSection
            caseTitle={props.caseTitle}
            caseConclusion={props.caseConclusion}
            onReprocessClick={props.onReprocessClick}
            reprocessLoading={props.reprocessLoading}
          />

          <CaseInfoSection device={props.device} from={props.from} />
        </Box>

        {/* Right side: Feedback */}
        <FeedbackSection
          feedback={props.feedback}
          feedbackReason={props.feedbackReason}
          feedbackLoading={props.feedbackLoading}
          feedbackError={props.feedbackError}
          isEditingFeedback={props.isEditingFeedback}
          feedbackOption={props.feedbackOption}
          feedbackText={props.feedbackText}
          onFeedbackOptionChange={props.onFeedbackOptionChange}
          onFeedbackTextChange={props.onFeedbackTextChange}
          onSubmitFeedback={props.onSubmitFeedback}
          onAgree={props.onAgree}
          onDisagree={props.onDisagree}
          onEditFeedback={props.onEditFeedback}
          onCancelEdit={props.onCancelEdit}
          setFeedbackText={props.setFeedbackText}
          setFeedbackOption={props.setFeedbackOption}
        />
      </Box>

      {/* Second Row: Case Details */}
      <CaseDetailsSection
        createdOn={props.createdOn}
        alertTime={props.alertTime}
        analyst={props.analyst}
        analystAvatar={props.analystAvatar}
        attackSurface={props.attackSurface}
      />

      {/* Key Evidence and Description */}
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          marginTop: theme.spacing(3),
        }}
      >
        <KeyEvidenceSection keyIndicators={props.keyIndicators} />
        <DescriptionSection description={props.description} />
      </Box>
    </Box>
  );
};

export default CaseSummarySection;
