import React from "react";
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { toShortDate } from "../../../utils/dateFormatter";

interface CaseDetailsSectionProps {
  createdOn: string;
  alertTime: string;
  analyst: string;
  analystAvatar: string;
  attackSurface: string;
}

const CaseDetailsSection: React.FC<CaseDetailsSectionProps> = ({
  createdOn,
  alertTime,
  analyst,
  analystAvatar,
  attackSurface,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(4),
        marginBottom: theme.spacing(1),
        fontSize: "0.875rem",
      }}
    >
      {attackSurface === "Email" ? (
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Email Rcvd{" "}
          <Tooltip title={createdOn}>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {toShortDate(createdOn)}
            </Typography>
          </Tooltip>
        </Typography>
      ) : (
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Alert Time:{" "}
          <Tooltip title={createdOn}>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {toShortDate(createdOn)}
            </Typography>
          </Tooltip>
        </Typography>
      )}

      <Typography variant="body2" color={theme.palette.text.secondary}>
        Alert Time{" "}
        <Tooltip title={alertTime}>
          <Typography
            variant="body2"
            component="span"
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            {toShortDate(alertTime)}
          </Typography>
        </Tooltip>
      </Typography>

      {/* Analyst with Avatar */}
      {/* <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Analyst
        </Typography>
        <Avatar
          src={analystAvatar}
          alt={analyst}
          sx={{
            width: "1.5rem",
            height: "1.5rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
        />
        <Typography variant="body2" component="span" fontWeight="bold">
          {analyst}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default CaseDetailsSection;
