// src/features/cases/components/FeedbackDisplay.tsx

import React from "react";
import { Chip, SxProps, Theme, Tooltip } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useTheme } from "@mui/system";

interface FeedbackDisplayProps {
  feedback: string | null; // "agree" or "disagree"
  reason?: string | null; // the feedback reason, if any
  textSx?: SxProps<Theme>;
}

const FeedbackDisplay: React.FC<FeedbackDisplayProps> = ({
  feedback,
  reason,
  textSx,
}) => {
  const theme = useTheme();
  if (!feedback) {
    return null;
  }

  const isAgree = feedback.toLowerCase() === "agree";

  // We'll wrap the Chip in a Tooltip if there's a reason present:
  const chip = (
    <Chip
      label={
        isAgree ? (
          <ThumbUpAltIcon sx={{ fontSize: "20px", paddingTop: "2px" }} />
        ) : (
          <ThumbDownAltIcon sx={{ fontSize: "20px", paddingTop: "2px" }} />
        )
      }
      sx={{
        backgroundColor: isAgree ? "success.main" : "error.main",
        color: "common.white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        borderRadius: "20px",
        height: "30px",
        width: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ml: "20px",
        "& .MuiChip-label": {
          padding: 0,
        },
        [theme.breakpoints.down("xl")]: {
          height: "25px",
          width: "25px",
          ...textSx,
        },
      }}
    />
  );

  return reason ? (
    <Tooltip title={`Comment: ${reason}`}>{chip}</Tooltip>
  ) : (
    <Tooltip title={"Comment: None"}>{chip}</Tooltip>
  );
};

export default FeedbackDisplay;
