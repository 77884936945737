import React from "react";
import { Tooltip, Typography, SxProps, Theme } from "@mui/material";
import { formatAlertDate } from "../../../utils/dateFormatter";

interface AlertTimeDisplayProps {
  timestamp: string;
  textSx?: SxProps<Theme>;
}

const AlertTimeDisplay: React.FC<AlertTimeDisplayProps> = ({
  timestamp,
  textSx,
}) => {
  const formattedDate = formatAlertDate(timestamp);

  return (
    <Tooltip title={timestamp} placement="bottom-start">
      <Typography
        variant="body2"
        sx={{
          cursor: "pointer",
          ...textSx,
        }}
      >
        {formattedDate}
      </Typography>
    </Tooltip>
  );
};

export default AlertTimeDisplay;
