import { CaseDetails } from "../../types/caseDetailsTypes";

export function transformCaseDetailApiResponse(apiCase: any): CaseDetails {
  // 1. Isolate the first feedback if any exist
  const rawFeedbacks = apiCase.feedbacks ?? [];
  const firstFeedback = rawFeedbacks.length > 0 ? rawFeedbacks[0] : null;

  // 2. Transform the single feedback into array of length 1, or empty array
  const feedbacks = firstFeedback
    ? [
        {
          user: {
            name: firstFeedback.user?.name ?? firstFeedback.user?.id ?? "",
          },
          updatedAt: firstFeedback.updatedAt ?? "",
          // old shape: “result” => new shape: “position”
          result: (firstFeedback.position ?? "").toLowerCase(),
          // old shape: “text” => new shape: “reason”
          text: firstFeedback.reason ?? "",
        },
      ]
    : [];

  // 3. Transform “analyses” to what code expects as “details.analyses”
  const analyses = (apiCase.analyses ?? []).map((analysis: any) => ({
    name: analysis.name ?? "",
    kind: analysis.kind ?? "",
    conclusion: analysis.decision ?? null, // from "decision"
    summary: analysis.summary ?? "",
    data: analysis.data
      ? {
          total: analysis.data.total ?? 0,
          malicious: analysis.data.malicious ?? [],
          benign: analysis.data.benign ?? [],
          inconclusive: analysis.data.inconclusive ?? [],
        }
      : undefined,
    questions: (analysis.questions ?? []).map((qa: any) =>
      qa
        ? {
            q: qa.q ?? "",
            a: qa.a ?? "",
          }
        : undefined
    ),
  }));

  // 4. Transform alerts (where code expects `data.from_address`, etc.)
  const alerts = (apiCase.alerts ?? []).map((alert: any) => ({
    id: alert.id ?? "",
    kind: alert.kind ?? "",
    timestamp: alert.timestamp ?? "",
    data: {
      from_address: apiCase.identity ?? "", // back-compat hack to support the new API
      //from_address: alert.data?.from_address ?? "",
      subject: alert.data?.subject ?? "",
    },
  }));

  // 5. Return the final CaseDetails shape
  return {
    id: apiCase.id ?? "",
    title: apiCase.title ?? "",
    attackSurface: apiCase.attackSurface ?? "",
    createdAt: apiCase.createdAt ?? "",
    updatedAt: apiCase.updatedAt ?? "",
    // old "conclusion" => new "decision"
    conclusion: apiCase.decision ?? "",
    // old "mitreStage" => new "mitreTactics"
    mitreStage: Array.isArray(apiCase.mitreTactics)
      ? apiCase.mitreTactics.join(", ")
      : "",
    severity: apiCase.severity ?? "",
    keyIndicators: apiCase.keyIndicators ?? [],
    summary: apiCase.summary ?? "",
    status: apiCase.state ?? "Unreviewed",
    analyst: apiCase.analyst ?? "",
    analystAvatar: "", // or some fallback
    alerts,
    feedbacks, // only the first (latest) feedback
    graph: apiCase.graph ?? { nodes: [], edges: [] },
    details: {
      analyses,
    },
    artifacts: apiCase.artifacts,
  };
}
