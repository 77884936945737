import { Theme } from "@mui/material/styles";

interface RefreshButtonStyles {
  color: string;
  marginLeft: string;
  backgroundColor: string;
  boxShadow: string;
  "&:hover": {
    backgroundColor: string;
    boxShadow: string;
  };
  borderRadius: string;
  transition: string;
  "&.Mui-disabled": {
    backgroundColor: string;
    color: string;
  };
}

interface FilterButtonStyles {
  color: string;
  borderBottom: string;
  fontWeight: string;
  "&:hover": {
    backgroundColor: string;
  };
}

interface HeaderRowStyles {
  backgroundColor: string;
  color: string;
  borderBottom: string;
  padding: string;
}

export const getRefreshButtonStyles = (theme: Theme): RefreshButtonStyles => {
  const isDarkMode = theme.palette.mode === "dark";

  return {
    color: theme.palette.info.main,
    marginLeft: "1rem",
    backgroundColor: isDarkMode
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
    boxShadow: theme.shadows[2],
    "&:hover": {
      backgroundColor: isDarkMode
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
      boxShadow: theme.shadows[4],
    },
    borderRadius: "4px",
    transition: "background-color 0.3s, box-shadow 0.3s",
    "&.Mui-disabled": {
      backgroundColor: isDarkMode
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
      color: theme.palette.grey[500],
    },
  };
};

export const getFilterButtonStyles = (
  theme: Theme,
  status: string,
  activeFilter: string
): FilterButtonStyles => {
  const isActive = activeFilter === status;
  const isDarkMode = theme.palette.mode === "dark";

  return {
    color: isActive
      ? isDarkMode
        ? theme.palette.info.main // Light color for active in dark mode
        : theme.palette.common.black // Dark color for active in light mode
      : isDarkMode
      ? theme.palette.grey[400] // Lighter grey for inactive in dark mode
      : theme.palette.grey[600], // Darker grey for inactive in light mode
    borderBottom: isActive ? `2px solid ${theme.palette.info.main}` : "none",
    fontWeight: isActive ? "bold" : "normal",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
};

export const getHeaderRowStyles = (theme: Theme): HeaderRowStyles => {
  const isDarkMode = theme.palette.mode === "dark";

  return {
    backgroundColor: isDarkMode
      ? theme.palette.grey[900]
      : theme.palette.grey[100],
    color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
    borderBottom: `1px solid ${
      isDarkMode ? theme.palette.grey[700] : theme.palette.grey[300]
    }`,
    padding: theme.spacing(2),
  };
};
