import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Tooltip,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Chip,
  alpha,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadAttachment } from "../../../api/caseDetailsApi";

import downloadSvg from "../../../assets/download_attachment.svg";
import VirusTotalIcon from "../../../assets/virustotal-logo.svg";
import EmailRepIcon from "../../../assets/email-rep-logo.png";
import IPInfoIcon from "../../../assets/ipinfo-logo.png";
import URLScanIOIcon from "../../../assets/urlscan-logo.svg";

// Interfaces
interface Question {
  q: string;
  a: string;
}

interface URLData {
  benign: string[];
  malicious: string[];
  inconclusive: string[];
}

interface Attachment {
  filename: string;
  mimetype: string;
  hash: string;
}

interface AttachmentData {
  benign: Attachment[];
  malicious: Attachment[];
  inconclusive: Attachment[];
}

interface Analysis {
  name: string; // e.g. "URLs", "Headers", "CTA", "Attachments"
  kind: string; // e.g. "urls", "headers", ...
  conclusion: string | null; // e.g. "Malicious", "Benign", "Inconclusive"
  summary: string;
  data?: URLData | AttachmentData;
  questions?: Question[];
}

interface AnalysisTabContentProps {
  analysis: Analysis;
}

// Minimal collapsible section
interface CollapsibleSectionProps {
  title: string;
  count?: number;
  startOpen?: boolean;
  copyData?: any;
  children?: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  count,
  startOpen = false,
  copyData,
  children,
}) => {
  const [open, setOpen] = useState(startOpen);

  const handleCopyClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (copyData) {
      const textToCopy =
        typeof copyData === "string"
          ? copyData
          : JSON.stringify(copyData, null, 2);
      navigator.clipboard.writeText(textToCopy);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          p: 0.5,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body2" fontWeight="bold">
            {count !== undefined ? `${count} ${title}` : title}
          </Typography>
          {copyData && (
            <Tooltip title={`Copy data for ${title}`}>
              <IconButton size="small" onClick={handleCopyClick}>
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
      {open && <Box sx={{ mt: 1, ml: 1 }}>{children}</Box>}
    </Box>
  );
};

// For long URLs, we’ll truncate them
const MAX_URL_LENGTH = 50;
const truncateURL = (url: string) =>
  url.length <= MAX_URL_LENGTH ? url : url.slice(0, MAX_URL_LENGTH) + "...";

/**
 * Helper: Map the iStep name to the question we display in the banner.
 */
function getBannerQuestion(name: string) {
  switch (name) {
    case "Headers":
      return "Are the email headers suspicious?";
    case "URLs":
      return "Are any URLs malicious?";
    case "Call To Action":
      return "Do the body contents appear suspicious?";
    case "Attachments":
      return "Are any of the attachments malicious?";
    default:
      return null;
  }
}

// 1) Define a map that tells us which chips to show for each analysis name:
const ENRICHMENT_CHIPS_MAP: Record<string, { label: string; icon: string }[]> =
  {
    URLs: [
      { label: "VirusTotal", icon: VirusTotalIcon },
      { label: "URLScanIO", icon: URLScanIOIcon },
    ],
    Headers: [
      { label: "EmailRep", icon: EmailRepIcon },
      { label: "VirusTotal", icon: VirusTotalIcon },
      { label: "IPInfo", icon: IPInfoIcon },
    ],
    Attachments: [{ label: "VirusTotal", icon: VirusTotalIcon }],
  };

const AnalysisTabContent: React.FC<AnalysisTabContentProps> = ({
  analysis,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // For Attachments
  const caseId = useAppSelector(
    (state: RootState) => state.caseDetails.caseDetail?.id
  );
  const attachmentDownloadLoading = useAppSelector(
    (state) => state.caseDetails.attachmentDownloadLoading
  );
  const attachmentDownloadError = useAppSelector(
    (state) => state.caseDetails.attachmentDownloadError
  );

  // Download modal
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadFile, setDownloadFile] = useState<{
    filehash: string;
    filename: string;
    mimetype: string;
  } | null>(null);

  // If this is URL data
  const urlData = analysis.data as URLData | undefined;
  const maliciousURLs = urlData?.malicious || [];
  const benignURLs = urlData?.benign || [];
  const inconclusiveURLs = urlData?.inconclusive || [];

  // Decide which sections open by default
  const maliciousStartOpen = maliciousURLs.length > 0;
  const benignStartOpen = !maliciousURLs.length && benignURLs.length > 0;
  const inconclusiveStartOpen = false;

  // Show 3 at a time
  const [maliciousShowCount, setMaliciousShowCount] = useState(
    Math.min(3, maliciousURLs.length)
  );
  const [benignShowCount, setBenignShowCount] = useState(
    Math.min(3, benignURLs.length)
  );
  const [inconclusiveShowCount, setInconclusiveShowCount] = useState(
    Math.min(3, inconclusiveURLs.length)
  );

  // If this is Attachment data
  const attachData = analysis.data as AttachmentData | undefined;
  const maliciousAttach = attachData?.malicious || [];
  const benignAttach = attachData?.benign || [];
  const inconclusiveAttach = attachData?.inconclusive || [];

  // Q&A => show only top 3
  const topQuestions = (analysis.questions || []).slice(0, 3);

  // “Malicious” => red banner, otherwise grey
  const isMalicious = analysis.conclusion === "Malicious";

  // Download logic
  const handleDownloadAttachment = (
    filehash: string,
    originalFilename: string,
    mimetype: string
  ) => {
    setDownloadFile({ filehash, filename: originalFilename, mimetype });
    setShowDownloadModal(true);
  };

  const handleConfirmDownload = async () => {
    if (!downloadFile || !caseId) {
      setShowDownloadModal(false);
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const appendedFilename = `${downloadFile.filename}.unsafe`;
      await dispatch(
        downloadAttachment({
          accessToken: token,
          caseId,
          filehash: downloadFile.filehash,
          filename: appendedFilename,
          mimetype: downloadFile.mimetype,
        })
      ).unwrap();
    } catch (err) {
      console.error("Download failed:", err);
    }
    setShowDownloadModal(false);
  };

  const handleCancelDownload = () => {
    setDownloadFile(null);
    setShowDownloadModal(false);
  };

  // Render summary
  const renderSummaryBullets = () => {
    if (!analysis.summary) return null;
    return analysis.summary
      .split("•")
      .map((l) => l.trim())
      .filter(Boolean)
      .map((line, idx) => (
        <ListItem
          key={idx}
          sx={{
            display: "list-item",
            listStyleType: "disc",
            p: 0,
            mb: 0.5,
          }}
        >
          <Typography variant="body2">{line}</Typography>
        </ListItem>
      ));
  };

  // Render up to showCount URLs
  const renderUrlList = (
    urls: string[],
    showCount: number,
    setShowCount: React.Dispatch<React.SetStateAction<number>>,
    color: string
  ) => {
    if (!urls.length) return null;
    const visibleItems = urls.slice(0, showCount);
    const canShowMore = showCount < urls.length;
    const canCollapse = showCount > 3;

    return (
      <Box>
        <List sx={{ listStyleType: "disc", pl: 3, mb: 0 }}>
          {visibleItems.map((url, i) => (
            <ListItem
              key={i}
              sx={{
                display: "list-item",
                listStyleType: "disc",
                p: 0,
                mb: 0.5,
              }}
            >
              <Tooltip title={url}>
                <Typography
                  variant="body2"
                  sx={{ color, wordBreak: "break-all", cursor: "default" }}
                >
                  {truncateURL(url)}
                </Typography>
              </Tooltip>
            </ListItem>
          ))}
        </List>

        <Box sx={{ display: "flex", gap: 1, mt: 1, ml: 3 }}>
          {canShowMore && (
            <Button
              variant="text"
              size="small"
              sx={{ color: theme.palette.grey[700] }}
              onClick={() =>
                setShowCount((prev) => Math.min(prev + 3, urls.length))
              }
            >
              Show {Math.min(3, urls.length - showCount)} more
            </Button>
          )}
          {canCollapse && (
            <Button
              variant="text"
              size="small"
              sx={{ color: theme.palette.grey[700] }}
              onClick={() => setShowCount(3)}
            >
              Collapse to 3
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  // Render attachments
  const renderAttachmentList = (attachments: Attachment[], color: string) => {
    if (!attachments.length) return null;
    return (
      <Box>
        {attachments.map((att, i) => (
          <Box
            key={`${att.hash}-${i}`}
            sx={{
              mb: 1,
              p: 1,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "grey.300",
              boxShadow: 1,
              wordBreak: "break-word",
              color,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 1,
              }}
            >
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  Filename:
                </Typography>
                <Typography variant="body2">{att.filename}</Typography>
              </Box>
              <Tooltip title="Download Attachment">
                <IconButton
                  onClick={() =>
                    handleDownloadAttachment(
                      att.hash,
                      att.filename,
                      att.mimetype
                    )
                  }
                  size="small"
                  disabled={attachmentDownloadLoading}
                >
                  <Box
                    component="img"
                    src={downloadSvg}
                    alt="Download Attachment"
                    sx={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography variant="body2" fontWeight="bold">
              MIME Type:
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {att.mimetype}
            </Typography>

            <Typography variant="body2" fontWeight="bold">
              Hash:
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {att.hash}
            </Typography>

            {attachmentDownloadError && (
              <Typography
                variant="body2"
                sx={{ color: theme.palette.error.main }}
              >
                {attachmentDownloadError}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  // Prepare the banner question
  const bannerQuestion = getBannerQuestion(analysis.name);
  const showBanner = !!bannerQuestion; // Only show banner if it's one of our iSteps

  // --- RENDER ---
  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      {showBanner && (
        <Box
          sx={{
            mb: 2,
            p: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
            // Use an alpha color for the background so it looks good in both light and dark modes:
            backgroundColor: isMalicious
              ? alpha(theme.palette.error.main, 0.15)
              : alpha(theme.palette.grey[400], 0.15),
            // For a dividing line or border:
            borderBottom: `2px solid ${
              isMalicious ? theme.palette.error.main : theme.palette.grey[400]
            }`,
          }}
        >
          {isMalicious ? (
            <WarningAmberIcon
              sx={{ color: theme.palette.error.main, fontSize: 18 }}
            />
          ) : (
            <InfoOutlinedIcon
              sx={{ color: theme.palette.grey[400], fontSize: 18 }}
            />
          )}

          <Typography
            variant="body2"
            fontWeight="bold"
            sx={{ color: theme.palette.text.primary }}
          >
            {bannerQuestion}
          </Typography>

          <Box sx={{ marginLeft: "auto" }} />

          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: isMalicious
                ? theme.palette.error.main
                : theme.palette.text.primary,
            }}
          >
            {isMalicious ? "Yes" : "No"}
          </Typography>
        </Box>
      )}

      {/* 2) Render the chips (based on analysis.name) */}
      {ENRICHMENT_CHIPS_MAP[analysis.name] && (
        <Box sx={{ display: "flex", gap: 1, mb: 2, flexWrap: "wrap" }}>
          {ENRICHMENT_CHIPS_MAP[analysis.name].map(({ label, icon }) => (
            <Chip
              key={label}
              variant="outlined"
              size="small"
              label={label}
              icon={
                <Box
                  component="img"
                  src={icon}
                  alt={label}
                  sx={{ width: 16, height: 16 }}
                />
              }
              sx={{
                fontWeight: "bold",
                borderRadius: "16px",
                fontSize: "0.75rem",
                height: "2rem",
                padding: "2px",
                ml: 1,
              }}
            />
          ))}
        </Box>
      )}

      {/* Summary */}
      <CollapsibleSection
        title="Summary"
        startOpen={true}
        copyData={analysis.summary}
      >
        <List sx={{ listStyleType: "disc", pl: 3, pt: 0 }}>
          {renderSummaryBullets()}
        </List>
      </CollapsibleSection>

      {/* If name === "URLs", show Malicious/Benign/Inconclusive with show-more logic */}
      {analysis.name === "URLs" && (
        <>
          {/* Malicious */}
          {maliciousURLs.length > 0 && (
            <CollapsibleSection
              title="Malicious URLs"
              startOpen={maliciousStartOpen}
              count={maliciousURLs.length}
              copyData={maliciousURLs}
            >
              {renderUrlList(
                maliciousURLs,
                maliciousShowCount,
                setMaliciousShowCount,
                theme.palette.error.main
              )}
            </CollapsibleSection>
          )}

          {/* Benign */}
          {benignURLs.length > 0 && (
            <CollapsibleSection
              title="Benign URLs"
              startOpen={benignStartOpen}
              count={benignURLs.length}
              copyData={benignURLs}
            >
              {renderUrlList(
                benignURLs,
                benignShowCount,
                setBenignShowCount,
                theme.palette.success.main
              )}
            </CollapsibleSection>
          )}

          {/* Inconclusive */}
          {inconclusiveURLs.length > 0 && (
            <CollapsibleSection
              title="Inconclusive URLs"
              startOpen={inconclusiveStartOpen}
              count={inconclusiveURLs.length}
              copyData={inconclusiveURLs}
            >
              {renderUrlList(
                inconclusiveURLs,
                inconclusiveShowCount,
                setInconclusiveShowCount,
                theme.palette.info.main
              )}
            </CollapsibleSection>
          )}
        </>
      )}

      {/* If name === "Attachments", show malicious/benign/inconclusive attachments */}
      {analysis.name === "Attachments" && attachData && (
        <>
          {maliciousAttach.length > 0 && (
            <CollapsibleSection
              title="Malicious Attachments"
              startOpen={true}
              count={maliciousAttach.length}
              copyData={maliciousAttach}
            >
              {renderAttachmentList(maliciousAttach, theme.palette.error.main)}
            </CollapsibleSection>
          )}

          {benignAttach.length > 0 && (
            <CollapsibleSection
              title="Benign Attachments"
              startOpen={!maliciousAttach.length && benignAttach.length > 0}
              count={benignAttach.length}
              copyData={benignAttach}
            >
              {renderAttachmentList(benignAttach, theme.palette.success.main)}
            </CollapsibleSection>
          )}

          {inconclusiveAttach.length > 0 && (
            <CollapsibleSection
              title="Inconclusive Attachments"
              startOpen={false}
              count={inconclusiveAttach.length}
              copyData={inconclusiveAttach}
            >
              {renderAttachmentList(
                inconclusiveAttach,
                theme.palette.info.main
              )}
            </CollapsibleSection>
          )}
        </>
      )}

      {/* Q&A - only top 3, questions NOT bold */}
      {topQuestions.length > 0 && (
        <CollapsibleSection
          title="Embed Top Q&A"
          startOpen={false}
          copyData={topQuestions}
        >
          {topQuestions.map((qa, idx) => (
            <Box key={idx} sx={{ mb: 1 }}>
              <Typography variant="body2">Q: {qa.q}</Typography>
              <Typography variant="body2" sx={{ ml: 2 }}>
                A: {qa.a}
              </Typography>
            </Box>
          ))}
        </CollapsibleSection>
      )}

      {/* Download Warning Modal */}
      <Dialog open={showDownloadModal} onClose={handleCancelDownload}>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ReportProblemIcon color="warning" />
            <Typography variant="h6" component="span">
              Warning
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            You are downloading files that could potentially be malicious. Are
            you sure you want to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDownload} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDownload}
            color="warning"
            variant="contained"
            disabled={attachmentDownloadLoading}
          >
            {attachmentDownloadLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Download"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnalysisTabContent;
