import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  TextField,
  Typography,
  Chip,
  Badge,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import { getHeaderRowStyles } from "../../../utils/themeStyles";

interface FilterPopupProps {
  conclusions: string[];
  statuses: string[];
  severities: string[];
  applyFilters: (filters: any) => void;
  currentFilters: any;
  // Add the callback for saving:
  onSaveCustomFilter: (name: string, filters: any) => void;
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  conclusions,
  statuses,
  severities,
  applyFilters,
  currentFilters,
  onSaveCustomFilter,
}) => {
  const theme = useTheme();

  // Local states for checkboxes/date/identities
  const [selectedConclusions, setSelectedConclusions] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [alertStartDate, setAlertStartDate] = useState<Date | null>(null);
  const [alertEndDate, setAlertEndDate] = useState<Date | null>(null);
  const [identities, setIdentities] = useState<string[]>([]);
  const [identityInput, setIdentityInput] = useState("");

  // For the "Save Filter" dialog
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [filterName, setFilterName] = useState("");

  // When currentFilters changes, sync them:
  useEffect(() => {
    if (currentFilters) {
      setSelectedConclusions(currentFilters.conclusions || []);
      setSelectedStatuses(currentFilters.statuses || []);
      setSelectedSeverities(currentFilters.severities || []);
      setStartDate(
        currentFilters.createdAt ? currentFilters.createdAt[0] : null
      );
      setEndDate(currentFilters.createdAt ? currentFilters.createdAt[1] : null);
      setAlertStartDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[0] : null
      );
      setAlertEndDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[1] : null
      );
      setIdentities(currentFilters.identities || []);
    }
  }, [currentFilters]);

  // Construct the filter object we want to apply/save
  const buildFilterObject = () => ({
    conclusions: selectedConclusions,
    statuses: selectedStatuses,
    severities: selectedSeverities,
    createdAt: [startDate, endDate],
    alertTimestamp: [alertStartDate, alertEndDate],
    identities,
  });

  // Called when user hits "Apply Filters"
  const handleApplyFilters = () => {
    applyFilters(buildFilterObject());
  };

  // Called when user wants to save the filter
  const handleSaveFilterClick = () => {
    setFilterName("");
    setSaveDialogOpen(true);
  };

  const handleSaveFilterConfirm = () => {
    if (!filterName) return; // or show error
    onSaveCustomFilter(filterName, buildFilterObject());
    setSaveDialogOpen(false);
  };

  // Clear All
  const handleClearAll = () => {
    setSelectedConclusions([]);
    setSelectedStatuses([]);
    setSelectedSeverities([]);
    setStartDate(null);
    setEndDate(null);
    setAlertStartDate(null);
    setAlertEndDate(null);
    setIdentities([]);
  };

  // Checkboxes
  const handleCheckboxChange =
    (setter: React.Dispatch<React.SetStateAction<string[]>>, value: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter((prev) =>
        e.target.checked ? [...prev, value] : prev.filter((v) => v !== value)
      );
    };

  // Count how many filters are active
  const totalFiltersApplied =
    selectedConclusions.length +
    selectedStatuses.length +
    selectedSeverities.length +
    (startDate || endDate ? 1 : 0) +
    (alertStartDate || alertEndDate ? 1 : 0) +
    identities.length;

  // Header row styles
  const headerRowStyles = getHeaderRowStyles(theme);

  return (
    <Box
      sx={{
        width: 300,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        boxShadow: 3,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1000,
      }}
    >
      {/* Popup Header */}
      <Box
        sx={{
          backgroundColor: headerRowStyles.backgroundColor,
          color: headerRowStyles.color,
          padding: headerRowStyles.padding,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottom: headerRowStyles.borderBottom,
        }}
      >
        <Typography variant="h6">Filter Cases</Typography>
      </Box>

      {/* Conclusion section */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={selectedConclusions.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Conclusion
            </Typography>
          </Badge>
        </Box>
        <FormGroup>
          {conclusions.map((c) => (
            <FormControlLabel
              key={c}
              control={
                <Checkbox
                  checked={selectedConclusions.includes(c)}
                  onChange={handleCheckboxChange(setSelectedConclusions, c)}
                  sx={{
                    color: theme.palette.info.main,
                    "&.Mui-checked": {
                      color: theme.palette.info.main,
                    },
                  }}
                />
              }
              label={c}
              sx={{
                "& .MuiTypography-root": {
                  color: theme.palette.mode === "dark" ? "white" : "grey.700",
                },
              }}
            />
          ))}
        </FormGroup>
      </Box>

      {/* Severity */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={selectedSeverities.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Severity
            </Typography>
          </Badge>
        </Box>
        <FormGroup>
          {severities.map((severity) => (
            <FormControlLabel
              key={severity}
              control={
                <Checkbox
                  checked={selectedSeverities.includes(severity)}
                  onChange={handleCheckboxChange(
                    setSelectedSeverities,
                    severity
                  )}
                  sx={{
                    color: theme.palette.info.main,
                    "&.Mui-checked": {
                      color: theme.palette.info.main,
                    },
                  }}
                />
              }
              label={severity}
              sx={{
                "& .MuiTypography-root": {
                  color: theme.palette.mode === "dark" ? "white" : "grey.700",
                },
              }}
            />
          ))}
        </FormGroup>
      </Box>

      {/* Created At Date Range */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={startDate || endDate ? 1 : 0}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Created At
            </Typography>
          </Badge>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
              ),
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1 }} />
              ),
            }}
          />
        </Box>
      </Box>

      {/* Alert Time Date Range */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={alertStartDate || alertEndDate ? 1 : 0}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Alert Time
            </Typography>
          </Badge>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <DatePicker
            label="Start Date"
            value={alertStartDate}
            onChange={(date) => setAlertStartDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
              ),
            }}
          />
          <DatePicker
            label="End Date"
            value={alertEndDate}
            onChange={(date) => setAlertEndDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1 }} />
              ),
            }}
          />
        </Box>
      </Box>

      {/* Identity */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={identities.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Identity
            </Typography>
          </Badge>
        </Box>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
          {identities.map((identity) => (
            <Chip
              key={identity}
              label={identity}
              onDelete={() =>
                setIdentities((prev) =>
                  prev.filter((item) => item !== identity)
                )
              }
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <InputBase
            value={identityInput}
            onChange={(e) => setIdentityInput(e.target.value)}
            placeholder="Search Identity"
            sx={{
              flex: 1,
              mr: 1,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(0.5, 1),
              color: theme.palette.text.primary,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[100],
            }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              if (identityInput && !identities.includes(identityInput)) {
                setIdentities([...identities, identityInput]);
              }
              setIdentityInput("");
            }}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            Add
          </Button>
        </Box>
      </Box>

      {/* Action Buttons */}
      <Box sx={{ padding: theme.spacing(2) }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {/* Apply Filters on its own row */}
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {`Apply Filters (${totalFiltersApplied})`}
          </Button>

          {/* Save + Clear on the same row */}
          <Box sx={{ display: "flex", gap: 1, justifyContent: "space-evenly" }}>
            <Button
              variant="text"
              onClick={handleSaveFilterClick}
              sx={{
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[800]
                      : theme.palette.grey[200],
                },
              }}
            >
              Save
            </Button>

            <Button
              onClick={handleClearAll}
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[800]
                      : theme.palette.grey[200],
                },
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Dialog to name the filter */}
      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Current Filter</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Name (max 8 chars)"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            inputProps={{ maxLength: 8 }}
            autoFocus
            margin="dense"
            fullWidth
          />
          <Box sx={{ mt: 2 }}>
            <strong>Note:</strong> This will save any advanced filters you’ve
            selected, including date ranges, identities, etc. You can have up to
            3 custom filters saved.
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveFilterConfirm}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FilterPopup;
