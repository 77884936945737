import React, { useCallback, useState, useRef } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../../app/hooks";
import { uploadEmail } from "../../../api/casesApi";

interface UploadEmailModalProps {
  open: boolean;
  onClose: () => void;
  addUploadItem: (id: string, fileName: string) => void;
  markUploadReady: (id: string) => void;
  markUploadError: (id: string, error: string) => void;
  pollCaseStatus: (id: string, token: string) => Promise<void>;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const UploadEmailModal: React.FC<UploadEmailModalProps> = ({
  open,
  onClose,
  addUploadItem,
  markUploadReady,
  markUploadError,
  pollCaseStatus,
}) => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  // For clearing the native file input
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // ---------- Drag and drop
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ---------- Fallback input
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // ---------- Close Modal & Reset
  const handleCloseModal = () => {
    setSelectedFile(null);
    setIsUploading(false);
    setUploadError(null);
    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onClose();
  };

  // ---------- Upload
  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);
    setUploadError(null);

    try {
      const token = await getAccessTokenSilently();
      const result = await dispatch(
        uploadEmail({ file: selectedFile, accessToken: token })
      ).unwrap();

      const emailId = result.email_id;
      if (emailId) {
        addUploadItem(emailId, selectedFile.name);
        pollCaseStatus(emailId, token);
      }

      handleCloseModal();
    } catch (error: any) {
      console.error("Failed to upload email:", error);
      setUploadError(error.message || "Failed to upload email");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box sx={style}>
        <Typography variant="h6" marginBottom={2}>
          Upload Email
        </Typography>

        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #ccc",
            padding: 2,
            textAlign: "center",
            marginBottom: 2,
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the file here...</Typography>
          ) : (
            <Typography>
              Drag &amp; drop an email file here, or click to select
            </Typography>
          )}
        </Box>

        {/* <Box marginBottom={2}>
          <input type="file" onChange={handleFileChange} ref={fileInputRef} />
        </Box> */}

        {selectedFile && (
          <Typography variant="body2" marginBottom={2}>
            Selected File: {selectedFile.name}
          </Typography>
        )}

        {uploadError && (
          <Typography variant="body2" color="error" marginBottom={2}>
            {uploadError}
          </Typography>
        )}

        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={handleCloseModal}
            disabled={isUploading}
            sx={{ marginRight: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!selectedFile || isUploading}
          >
            {isUploading ? (
              <>
                <CircularProgress size={20} sx={{ marginRight: 1 }} />
                Uploading...
              </>
            ) : (
              "Upload"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadEmailModal;
