import React from "react";
import { Box, Skeleton } from "@mui/material";

interface EmbedCircleSkeletonProps {
  width: number; // in pixels
  height: number; // in pixels
}

const EmbedCircleSkeleton: React.FC<EmbedCircleSkeletonProps> = ({
  width,
  height,
}) => {
  const circleSize = Math.min(width, height);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Skeleton
        variant="circular"
        width={circleSize * 1.5}
        height={circleSize * 1.5}
      />
    </Box>
  );
};

export default EmbedCircleSkeleton;
