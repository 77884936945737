import React, { useState, useEffect, useRef } from "react";
import { Box, Tabs, Tab, Badge, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AnalysisTabContent from "./AnalysisTabContent";
import ArtifactsTab from "./ArtifactsTab";

interface URLData {
  benign: string[];
  malicious: string[];
  inconclusive: string[];
}

interface Attachment {
  filename: string;
  mimetype: string;
  hash: string;
}

interface AttachmentData {
  benign: Attachment[];
  malicious: Attachment[];
  inconclusive: Attachment[];
}

interface Question {
  q: string;
  a: string;
}

interface Analysis {
  name: string;
  kind: string;
  conclusion: string | null;
  summary: string;
  data?: URLData | AttachmentData;
  questions?: Question[]; // Q&A
}

interface InvestigativeStepsTabsProps {
  analyses: Analysis[];
  artifacts?: Record<string, any>;
}

const InvestigativeStepsTabs: React.FC<InvestigativeStepsTabsProps> = ({
  analyses = [],
  artifacts = {},
}) => {
  const theme = useTheme();
  const tabsRef = useRef<HTMLDivElement | null>(null);

  // Find index of first malicious tab if any
  const maliciousTabIndex = analyses.findIndex(
    (analysis) => analysis.conclusion === "Malicious"
  );
  const [activeTab, setActiveTab] = useState(
    maliciousTabIndex !== -1 ? maliciousTabIndex : 0
  );

  // If no analyses exist, just fallback to default tab names:
  // const defaultTabs = [
  //   "URLs",
  //   "Attachments",
  //   "Call To Action",
  //   "Headers",
  //   "Artifacts",
  // ];
  const analysisTabNames = analyses.map((a) => a.name);
  const tabsToRender = [...analysisTabNames, "Artifacts"];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // On mount or tab change, scroll selected tab into view
  useEffect(() => {
    if (!tabsRef.current) return;
    const selectedTab = tabsRef.current.querySelector(
      '[role="tab"][aria-selected="true"]'
    ) as HTMLElement | null;
    if (selectedTab) {
      const timer = setTimeout(() => {
        selectedTab.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [activeTab]);

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: theme.shadows[1],
      }}
    >
      {/* Tabs Section */}
      <Box ref={tabsRef}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            "& .MuiTab-root": {
              fontSize: "0.875rem",
              minHeight: "2.5rem",
              minWidth: "130px",
              flex: 1,
              paddingTop: "1.3rem",
              paddingBottom: "1rem",
              textTransform: "none",
              color: theme.palette.grey[600],
              [theme.breakpoints.down("xl")]: {
                fontSize: "0.8rem",
              },
              "&.Mui-selected": {
                color: theme.palette.text.primary,
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.info.main,
            },
          }}
        >
          {tabsToRender.map((tabName, index) => {
            // If this is the "Artifacts" tab (last one):
            if (index === tabsToRender.length - 1) {
              return <Tab key={index} label="Artifacts" />;
            }

            if (analyses.length === 0) {
              // No analyses => just plain tabs
              return <Tab key={index} label={tabName} />;
            }

            // We do have analyses => show a tab with badge if needed
            const analysis = analyses[index];
            const isMalicious = analysis.conclusion === "Malicious";
            const isURLsTab = analysis.name === "URLs";
            const isAttachmentsTab = analysis.name === "Attachments";

            // Decide how to display a badge
            let badgeVariant: "dot" | "standard" | undefined;
            let badgeColor: "error" | "primary" | undefined;
            let badgeContent: React.ReactNode = null;

            if (isMalicious) {
              badgeVariant = "dot";
              badgeColor = "error";
            } else if (isURLsTab && analysis.data) {
              // It's a URLs tab => show total URLs
              badgeVariant = "standard";
              badgeColor = "primary";
              const data = analysis.data as URLData;
              const total =
                (data.benign?.length ?? 0) +
                (data.malicious?.length ?? 0) +
                (data.inconclusive?.length ?? 0);
              badgeContent = total > 9 ? "9+" : total;
            } else if (isAttachmentsTab && analysis.data) {
              // It's an Attachments tab => show total attachments
              badgeVariant = "standard";
              badgeColor = "primary";
              const data = analysis.data as AttachmentData;
              const total =
                (data.benign?.length ?? 0) +
                (data.malicious?.length ?? 0) +
                (data.inconclusive?.length ?? 0);
              badgeContent = total > 9 ? "9+" : total;
            }

            const tabLabel = <Box>{tabName}</Box>;

            if (isMalicious || badgeContent !== null) {
              return (
                <Tab
                  key={index}
                  label={
                    <Badge
                      color={badgeColor}
                      variant={badgeVariant}
                      badgeContent={badgeContent}
                      showZero
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiBadge-badge": {
                          top: isMalicious ? "-2%" : "20%",
                          right: isMalicious ? "-10px" : "-8px",
                        },
                      }}
                    >
                      {tabLabel}
                    </Badge>
                  }
                />
              );
            }
            // else plain tab
            return <Tab key={index} label={tabName} />;
          })}
        </Tabs>
      </Box>

      {/* Rendered Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden", // We'll let each tab handle its own overflow
          position: "relative",
        }}
      >
        {tabsToRender.map((_, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? "block" : "none",
              width: "100%",
              height: "100%",
            }}
          >
            {/* If this is the Artifacts tab: */}
            {index === tabsToRender.length - 1 ? (
              <ArtifactsTab artifacts={artifacts} />
            ) : (
              /* Otherwise render the analysis content */
              <>
                {analyses.length === 0 ? (
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2" fontWeight="bold">
                      No Data
                    </Typography>
                  </Box>
                ) : (
                  <AnalysisTabContent analysis={analyses[index]} />
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default InvestigativeStepsTabs;
