// AppRoutes.jsx
import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import DashboardPage from "../pages/DashboardPage2";
import CasesListPage from "../pages/CasesListPage";
import CaseDetailsPage from "../pages/CaseDetailsPage";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "./PrivateRoute";
import { useAuth0 } from "@auth0/auth0-react";
import MyLottieAnimation from "../common/components/MyLottieAnimation";

const AppRoutes = () => {
  const { isAuthenticated } = useAuth0();
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    // Check localStorage for the animation flag
    const hasSeenAnimation = localStorage.getItem("hasSeenAnimation");
    if (hasSeenAnimation) {
      setAnimationCompleted(true);
    }
  }, []);

  // Set animation completion flag in localStorage once animation completes
  const handleAnimationComplete = () => {
    localStorage.setItem("hasSeenAnimation", "true");
    setAnimationCompleted(true);
  };

  // Wait until authentication state is fully determined and animation is complete
  if (!animationCompleted) {
    return (
      <MyLottieAnimation
        onAnimationComplete={handleAnimationComplete}
        speed={2.5} // Janky speed controller
      />
    );
  }

  return (
    <MainLayout>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ flex: 1, padding: "20px" }}>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/cases"
              element={
                <PrivateRoute>
                  <CasesListPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/cases/:id"
              element={
                <PrivateRoute>
                  <CaseDetailsPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </MainLayout>
  );
};

export default AppRoutes;
