import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { styled } from "@mui/system";

interface Analysis {
  name: string;
  conclusion: string | null;
}

interface IStepsDisplayProps {
  analyses: Analysis[];
}

const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMalicious" && prop !== "color",
})<{ isMalicious: boolean; color: string }>(
  ({ theme, isMalicious, color }) => ({
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: color,
    cursor: "pointer",
    position: "relative",
  })
);

const IStepsDisplay: React.FC<IStepsDisplayProps> = ({ analyses }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, auto)",
        gap: theme.spacing(0.5),
        width: "auto",
      }}
    >
      {analyses.map((analysis, index) => {
        // Normalize
        const normalized = analysis.conclusion
          ? analysis.conclusion.trim().toLowerCase()
          : "";

        let color = theme.palette.grey[300];
        let isMalicious = false;

        if (normalized === "benign") {
          color = theme.palette.grey[300];
        } else if (normalized === "malicious") {
          color = theme.palette.error.main;
          isMalicious = true;
        } else if (normalized === "malicious_benign") {
          color = theme.palette.warning.main;
          isMalicious = true;
        }
        // else remains grey[300]

        return (
          <Tooltip
            key={index}
            title={`${analysis.name}: ${
              analysis.conclusion || "No Conclusion"
            }`}
            arrow
          >
            <Dot color={color} isMalicious={isMalicious} />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default IStepsDisplay;
