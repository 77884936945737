import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  Controls,
  Background,
  Position,
  useReactFlow,
} from "@xyflow/react";
import dagre from "@dagrejs/dagre";
import "@xyflow/react/dist/style.css";
import { useTheme } from "@mui/system";
import {
  CircularProgress,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomNode from "./CustomNode";
import CustomEdge from "./CustomEdge";

import ArtifactsTab from "./ArtifactsTab";
import RadialMenu from "./RadialMenu";

import { fetchArtifactPreview } from "../../../api/caseDetailsApi";
import { RootState } from "../../../app/rootReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import ArtifactDetails from "./ArtifactDetails";

const nodeWidth = 150;
const nodeHeight = 150;
const layoutDirection = "LR";

// Optional: helper to extract domain for node labels
function extractDomain(url: string) {
  try {
    const parsed = new URL(url);
    return parsed.hostname.replace(/^www\./, "");
  } catch {
    return url.replace(/^www\./, "");
  }
}

function transformNodes(nodesData: any[]): Node[] {
  return nodesData.map((nodeData: any) => {
    let label = nodeData.displayName || nodeData.id;
    if (nodeData.kind === "Email URL") {
      label = extractDomain(label);
    }
    const isMalicious = nodeData.decision === "Malicious";
    return {
      id: nodeData.id,
      position: { x: 0, y: 0 },
      data: {
        nodeId: nodeData.id,
        label,
        searchKey: nodeData.displayName || "",
        kind: nodeData.kind,
        decision: nodeData.decision || "",
        isMalicious,
      },
      type: "custom",
    };
  });
}

function transformEdges(edgesData: any[]): Edge[] {
  return edgesData.map((edgeData: any) => ({
    id: `${edgeData.u}-${edgeData.v}`,
    source: edgeData.u,
    target: edgeData.v,
    type: "custom",
    data: {
      sourceIsTrigger: edgeData.sourceIsTrigger,
      targetIsTrigger: edgeData.targetIsTrigger,
    },
  }));
}

function getLayoutedElements(nodes: Node[], edges: Edge[]) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: layoutDirection,
    align: "UL",
    nodesep: 80,
    ranksep: 100,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);

  return nodes.map((node) => {
    const nodePos = dagreGraph.node(node.id);
    node.position = {
      x: nodePos.x - nodeWidth / 2,
      y: nodePos.y - nodeHeight / 2,
    };
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;
    return node;
  });
}

interface ReactFlowSectionProps {
  data: { nodes: any[]; edges: any[] };
  artifacts: Record<string, any>;
}

/**
 * ReactFlowSection: sets up the <ReactFlowProvider> and calls <Flow />
 */
const ReactFlowSection: React.FC<ReactFlowSectionProps> = ({
  data,
  artifacts,
}) => {
  const theme = useTheme();
  const initialNodes = useMemo(() => transformNodes(data.nodes), [data.nodes]);
  const initialEdges = useMemo(() => transformEdges(data.edges), [data.edges]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.grey[200],
        position: "relative",
      }}
    >
      <ReactFlowProvider>
        <Flow
          initialNodes={initialNodes}
          initialEdges={initialEdges}
          artifacts={artifacts}
        />
      </ReactFlowProvider>
    </div>
  );
};

export default ReactFlowSection;

interface FlowProps {
  initialNodes: Node[];
  initialEdges: Edge[];
  artifacts: Record<string, any>;
}

function Flow({
  initialNodes,
  initialEdges,
  artifacts,
}: {
  initialNodes: Node[];
  initialEdges: Edge[];
  artifacts: Record<string, any>;
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const flowContainerRef = useRef<HTMLDivElement | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  // Access Redux state for the preview
  const { previewLoading, previewError, previewUrl } = useAppSelector(
    (state: RootState) => state.caseDetails
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [expandedNode, setExpandedNode] = useState<Node | null>(null);
  const [activeSlice, setActiveSlice] = useState<
    "" | "Details" | "Preview" | "Questions" | "Generate Preview"
  >("");
  const [detailsArtifactKey, setDetailsArtifactKey] = useState<string | null>(
    null
  );

  const reactFlowInstance = useReactFlow();
  const hasLayouted = useRef(false);

  // Lay out the nodes/edges only once
  useEffect(() => {
    if (!hasLayouted.current) {
      const layouted = getLayoutedElements([...nodes], [...edges]);
      setNodes(layouted);
      hasLayouted.current = true;
    }
  }, [nodes, edges, setNodes]);

  // Cleanup the object URL for the preview if user closes or unmounts
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // For radial menu position
  const [menuPos, setMenuPos] = useState<{ x: number; y: number } | null>(null);
  const [flowZoom, setFlowZoom] = useState(1.0);

  const onMove = useCallback(() => {
    if (!expandedNode || !flowContainerRef.current) return;
    const nodeCenter = {
      x: expandedNode.position.x + nodeWidth / 2,
      y: expandedNode.position.y + nodeHeight / 2,
    };
    // Convert to page coords
    const screenPos = reactFlowInstance.flowToScreenPosition(nodeCenter);
    const containerRect = flowContainerRef.current.getBoundingClientRect();
    setMenuPos({
      x: screenPos.x - containerRect.left,
      y: screenPos.y - containerRect.top,
    });
    setFlowZoom(reactFlowInstance.getZoom());
  }, [expandedNode, reactFlowInstance]);

  useEffect(() => {
    if (expandedNode) {
      onMove();
    }
  }, [expandedNode, onMove]);

  const onNodeClick = useCallback(
    (evt: React.MouseEvent, node: Node) => {
      evt.stopPropagation();
      if (expandedNode?.id === node.id) {
        // close the radial
        setExpandedNode(null);
        setActiveSlice("");
        setDetailsArtifactKey(null);
      } else {
        // center and open radial
        setExpandedNode(null);
        setActiveSlice("");
        setDetailsArtifactKey(null);

        reactFlowInstance.setCenter(
          node.position.x + nodeWidth / 2,
          node.position.y + nodeHeight / 2,
          { zoom: 2.0, duration: 300 }
        );

        setTimeout(() => {
          setExpandedNode(node);
        }, 310);
      }
    },
    [expandedNode, reactFlowInstance]
  );

  const onPaneClick = useCallback(() => {
    setExpandedNode(null);
    setActiveSlice("");
    setDetailsArtifactKey(null);
  }, []);

  const onMoveStart = useCallback(() => {
    setExpandedNode(null);
    setActiveSlice("");
    setDetailsArtifactKey(null);
  }, []);

  /**
   * handleSliceClick = user picks "Details" / "Preview" / "Questions" from radial
   */
  const handleSliceClick = useCallback(
    async (sliceLabel: string) => {
      if (!expandedNode) return;

      // If user re-clicks the same slice, close it
      if (activeSlice === sliceLabel) {
        setActiveSlice("");
        setDetailsArtifactKey(null);
        return;
      }

      // Open the new slice
      setActiveSlice(sliceLabel as any);
      setDetailsArtifactKey(null);

      if (sliceLabel === "Details") {
        const artifactKey = expandedNode.data.searchKey;
        if (artifactKey && artifacts[artifactKey as string]) {
          setDetailsArtifactKey(artifactKey as string);
        }
      } else if (sliceLabel === "Preview") {
        // Attempt to fetch preview if previewId is present
        const artifactKey = expandedNode.data.searchKey;
        if (artifactKey && artifacts[artifactKey as string]) {
          const maybeArtifact = artifacts[artifactKey as string];
          if (maybeArtifact?.previewId) {
            try {
              const token = await getAccessTokenSilently();
              // Optionally remove "/cases" if server doesn't expect it
              const normalizedPreviewId = maybeArtifact.previewId.replace(
                /^\/cases/,
                ""
              );
              dispatch(
                fetchArtifactPreview({
                  accessToken: token,
                  previewId: normalizedPreviewId,
                })
              );
            } catch (err) {
              console.error("Error retrieving token for preview:", err);
            }
          }
        }
      }
    },
    [
      activeSlice,
      expandedNode,
      artifacts,
      dispatch,
      getAccessTokenSilently,
      setDetailsArtifactKey,
      setActiveSlice,
    ]
  );

  // Close the side or overlay
  const handleClosePopup = () => {
    setActiveSlice("");
    setDetailsArtifactKey(null);
  };

  // ========== STYLES ==========
  let radialMenuStyle: React.CSSProperties = { display: "none" };
  let sidePopupStyle: React.CSSProperties = { display: "none" };
  let overlayStyle: React.CSSProperties = { display: "none" };

  if (expandedNode && menuPos) {
    radialMenuStyle = {
      position: "absolute",
      left: menuPos.x,
      top: menuPos.y,
      transform: "translate(-80%, -45%)",
      zIndex: 9999,
      display: "block",
      pointerEvents: "none",
    };
  }

  // Decide if we need a side popup
  // We show side popup for: "Details", "Questions", or "Preview" if there's NO previewId
  const artifactKey = expandedNode?.data.searchKey || "";
  const maybeArtifact = artifactKey ? artifacts[artifactKey as string] : null;
  const hasPreviewId = !!maybeArtifact?.previewId;

  // If activeSlice is "Details" or "Questions", or if activeSlice is "Preview" BUT there's no previewId
  if (
    activeSlice &&
    (activeSlice === "Details" ||
      activeSlice === "Questions" ||
      (activeSlice === "Preview" && !hasPreviewId))
  ) {
    sidePopupStyle = {
      position: "absolute",
      left: menuPos ? menuPos.x + 160 : 100,
      top: menuPos ? menuPos.y - 120 : 100,
      width: "500px",
      maxHeight: "500px",
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 8,
      zIndex: 20000, // <--- bump up so it's definitely visible
      padding: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      display: "block",
      overflowY: "auto",
    };
  }

  // If activeSlice is "Preview" and we do have a previewId => show the overlay
  if (activeSlice === "Preview" && hasPreviewId) {
    overlayStyle = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // Instead of 1500x1500, let's keep it 900x900
      width: "900px",
      height: "900px",
      maxWidth: "90vw", // ensure it doesn't overflow the screen
      maxHeight: "90vh",
      background: theme.palette.background.paper,
      borderRadius: 8,
      zIndex: 30000,
      padding: "16px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      display: "block",
      overflow: "auto",
    };
  }

  // Side popup content
  let sidePopupHeader = activeSlice;
  let sidePopupContent: React.ReactNode = null;

  if (activeSlice === "Details") {
    sidePopupHeader = "Details";
    if (detailsArtifactKey && artifacts[detailsArtifactKey]) {
      sidePopupContent = (
        <ArtifactDetails
          artifactKey={detailsArtifactKey}
          artifact={artifacts[detailsArtifactKey]}
        />
      );
    } else {
      sidePopupContent = (
        <div>No artifact found for key: {detailsArtifactKey}</div>
      );
    }
  } else if (activeSlice === "Questions") {
    sidePopupHeader = "Questions";
    sidePopupContent = (
      <div>Q&A about {expandedNode?.data.label as string}</div>
    );
  } else if (activeSlice === "Preview" && !hasPreviewId) {
    // Show the "Generate Preview" UI
    sidePopupHeader = "Preview";
    sidePopupContent = (
      <Box textAlign="center">
        <Typography variant="body1" mb={2}>
          No existing preview found for this artifact.
        </Typography>
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="outlined"
            color="info"
            onClick={handleClosePopup}
            sx={{ minWidth: 120 }}
          >
            Cancel
          </Button>
          {/* <Button
            variant="contained"
            color="info"
            onClick={() => {
              console.log("Generating Preview...");
              // TODO:  might do real logic here eventually
            }}
            sx={{ minWidth: 120 }}
          >
            Generate
          </Button> */}
        </Box>
      </Box>
    );
  }

  // Overlay content if we do have a previewId
  let overlayContent: React.ReactNode = null;

  if (activeSlice === "Preview" && hasPreviewId) {
    if (previewLoading) {
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          p={1}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" sx={{ m: 0 }}>
              Preview
            </Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <hr style={{ margin: "6px 0 10px 0" }} />

          {/* LOADING SPINNER */}
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
            <Typography variant="body1" ml={2}>
              Loading preview...
            </Typography>
          </Box>
        </Box>
      );
    } else if (previewError) {
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" sx={{ m: 0 }}>
              Preview
            </Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <hr style={{ margin: "6px 0 10px 0" }} />

          {/* ERROR CONTENT */}
          <Typography color="error" variant="body1" mt={2}>
            Error: {previewError}
          </Typography>
        </Box>
      );
    } else if (previewUrl) {
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          p={1}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" sx={{ m: 0 }}>
              Preview
            </Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <hr style={{ margin: "6px 0 10px 0" }} />

          {/* IMAGE CONTENT */}
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="auto"
          >
            <img
              src={previewUrl}
              alt="Artifact Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 4,
              }}
            />
          </Box>
        </Box>
      );
    } else {
      // Fallback
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" sx={{ m: 0 }}>
              Preview
            </Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <hr style={{ margin: "6px 0 10px 0" }} />

          <Typography variant="body1">No preview available.</Typography>
        </Box>
      );
    }
  }
  const proOptions = { hideAttribution: true };

  return (
    <div
      ref={flowContainerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        proOptions={proOptions}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={{ custom: CustomNode }}
        edgeTypes={{ custom: CustomEdge }}
        onNodeClick={onNodeClick}
        onPaneClick={onPaneClick}
        onMove={onMove}
        onMoveStart={onMoveStart}
        onInit={(rfInstance) => {
          rfInstance.fitView({ padding: 0.2 });
        }}
        fitView
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Controls
          position="top-left"
          style={{
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            padding: "4px",
            zIndex: 10,
          }}
        />
        <Background color={theme.palette.grey[300]} />
      </ReactFlow>

      {/* RADIAL MENU */}
      {expandedNode && (
        <div style={radialMenuStyle}>
          <RadialMenu
            isOpen
            isMalicious={Boolean(expandedNode.data.isMalicious)}
            onSliceClick={handleSliceClick}
            scaleFactor={flowZoom * 0.65}
          />
        </div>
      )}

      {/* Side popup for (Details, Questions, or "Generate Preview") */}
      {activeSlice &&
        (activeSlice === "Details" ||
          activeSlice === "Questions" ||
          (activeSlice === "Preview" && !hasPreviewId)) && (
          <div style={sidePopupStyle}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ m: 0 }}>
                {sidePopupHeader}
              </Typography>
              <IconButton size="small" onClick={handleClosePopup}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <hr style={{ margin: "6px 0 10px 0" }} />
            <div>{sidePopupContent}</div>
          </div>
        )}

      {/* Overlay if has previewId */}
      {activeSlice === "Preview" && hasPreviewId && (
        <div style={overlayStyle}>{overlayContent}</div>
      )}
    </div>
  );
}
